.header {
  // color: $blue;
  // background-color: $white;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding: 25px 0;
  z-index: 10;
  transition: padding 0.3s $easing, color 0.3s $easing, background-color 0.3s $easing;

  @media (max-width: $md - 1px) {
    padding: 17px 0;
  }

  .svg {
    display: block;
  }

  color: $white;
  background-color: transparent;

  &.white {
    color: $blue;
    background-color: $white;
  }

  &.blue {
    background-color: transparent;
    color: $blue;
  }

  // &.trans {
  //   color: $white;
  //   background-color: transparent;

  //   &.blue {
  //     color: $blue;
  //   }
  // }

  &.scrolled {
    padding: 17px 0;
    color: $blue;
    background-color: $white;
  }

  > .container, &_langs, &_others {
    display: flex;
    align-items: center;
    justify-content: space-between;
  } 

  &_menu {
    @media (max-width: $md - 1px) {
      transform: translateX(100%);
      pointer-events: none;
      opacity: 0;
      transition: opacity 0.4s $easing, transform 0.01ms linear 0.4s;

      &::after {
        content: '';
        position: fixed;
        width: 100%;
        height: 200%;
        top: 0;
        left: 0;
        background-color: $blue;
        z-index: -1;
      }
    }
  }

  &_mobothers {
    position: fixed;
    bottom: 0;
    width: 100%;
    left: 0;
    height: 70px;
    background-color: $blue;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;
    opacity: 0;
    transition: opacity 0.3s $easing;
    pointer-events: none;

    > a {
      padding: 0 20px;
    }

    .svg {
      &_phone {
        width: 18px;
        height: 18px;
      }

      &_mail {
        width: 20px;
        height: 16px;
      }

      path {
        fill: currentColor;
      }
    }
  }

  &_others {
    margin: 0 -15px;

    @media (min-width: $md) and (max-width: $lg - 1px) {
      margin: 0 -6px;
    }

    .btn {
      color: currentColor;
      border-color: currentColor;
      margin-right: 15px;
      white-space: nowrap;

      @media (min-width: $md) and (max-width: $lg - 1px) {
        margin-right: 6px;
      }

      @media (max-width: $md - 1px) {
        display: none;
      }
    }
  }

  &_langs {
    position: relative;

    @media (max-width: $md - 1px) {
      display: flex;
      position: fixed;
      left: 20px;
      bottom: 23px;
      z-index: 11;
      opacity: 0;
      transition: opacity 0.3s $easing;
      pointer-events: none;
    }

    &_sub {
      position: absolute;
      display: block;
      top: 25px;
      // background-color: $white;
      color: $black;
      padding: 13px 3px 12px 0px;
      left: 15px;
      opacity: 0;
      transform: translateY(20px);
      visibility: hidden;
      transition: .3s ease-in-out;

      @media (max-width: $md - 1px) {
        display: flex;
        opacity: 1;
        transform: translate(0px, 0px);
        position: relative;
        visibility: visible;
        top: 0px;
        left: 0px;
        background-color: transparent;
        color: white;
      }

      .header_lang {
        display: block;
        padding: 5px 15px;
        background-color: $white;
        opacity: 1;
        transition: opacity .3s ease-in-out;

        @media (max-width: $md - 1px) {
          background-color: transparent;
        }

        &:hover {
          opacity: .9;
        }
      }
    }

    &:hover {
      
      .header_langs_sub {
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
      }
    }
  }

  &_lang {
    padding: 0 15px;
    line-height: 24px;
    font-size: 12px;
    letter-spacing: 0.12em;
    text-transform: uppercase;
    font-weight: 700;
    position: relative;

    @media (min-width: $md) and (max-width: $lg - 1px) {
      padding: 0 6px;
    }

    &.active {
      &::before {
        content: '';
        position: absolute;
        top: 100%;
        left: 50%;
        width: calc(100% - 30px);
        transform: translateX(-50%);
        height: 2px;
        background-color: currentColor;

        @media (min-width: $md) and (max-width: $lg - 1px) {
          width: calc(100% - 12px);
        }
      }
    }
  }

  &_search {
    padding: 8px 15px;
    position: relative;
    transition: opacity 0.4s $easing;

    @media (min-width: $md) and (max-width: $lg - 1px) {
      padding: 8px 6px;
    }

    .svg {
      height: 24px;
      width: 24px;
    }
  }

  &_logo {
    .svg {
      height: 46px;
      width: 92px;
    }
  }

  &_search, &_logo {
    .svg path,
    .svg rect,
    .svg polygon {
      fill: currentColor;
    }
  }

  &_mobToggler {
    padding: 8px 15px;
    cursor: pointer;

    @media (min-width: $md) {
      display: none;
    }

    .lines {
      width: 24px;
      height: 18px;
      position: relative;
      transition: transform 0.4s $easing;

      .line {
        height: 2px;
        width: 100%;
        position: absolute;
        background-color: currentColor;
        left: 0;
        transition: transform 0.4s $easing, opacity 0.4s $easing, top 0.4s $easing;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2) {
          top: 8px;
        }

        &:nth-child(3) {
          top: 16px;
        }
      }
    }
  }

  &.open {
    color: $white !important;

    .header {
      &_menu {
        @media (max-width: $md - 1px) {
          transform: translateX(0%);
          pointer-events: auto;
          opacity: 1;
          transition: opacity 0.4s $easing 0.01ms, transform 0.01ms linear;
        }
      }

      &_mobToggler {
        .lines {
          .line {
            &:nth-child(1) {
              top: 8px;
              transform: rotate(45deg);
            }

            &:nth-child(2) {
              transform: rotate(45deg);
              opacity: 0;
            }

            &:nth-child(3) {
              top: 8px;
              transform: rotate(-45deg);
            }
          }
        }
      }

      &_search {
        opacity: 0;
        pointer-events: none;
      }

      &_mobothers, &_langs {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }
}