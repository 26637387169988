.ads_counter {
  position: absolute;
  color: $blue;
  height: 20px;
  width: 20px;
  background-color: $white;
  border-radius: 50%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  transform: translateY(-55%);
  right: 0;
  font-weight: 600;
  font-size: 11px;
  letter-spacing: 0;
  font-family: Arial, Helvetica, sans-serif;
  transition: background-color 0.3s cubic-bezier(0.2, 0.6, 0.3, 1);

  @media (max-width: $md - 1px) {
    color: $blue;
    background-color: $white;
    z-index: 1;
    position: relative;
    margin-left: 5px;
    bottom: 2px;
    top: auto;
    transform: translateY(0);
  }
}

.header.scrolled,
.menu_mega .mega_toggler-inner {
  .ads_counter {
    background-color: $blue;
    color: $white;
    @media (max-width: $md - 1px) {
      color: $blue;
      background-color: $white;
    }
  }
}

.mega_toggler-inner {
  .ads_counter {
    margin-left: 10px;
    bottom: -4px;
  }
}
