@include keyframes(bounceOutDown) {
  20% {
    transform: translate3d(0, 10px, 0);
  }

  40%, 45% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 0;
    transform: translate3d(0, 2000px, 0);
  }
}

@include keyframes(bounceUpDown) {
  20% {
    transform: translate3d(0, 7%, 0);
  }

  40% {
    transform: translate3d(0, -4%, 0);
  }

  60% {
    transform: translate3d(0, -4%, 0);
  }

  90% {
    transform: translate3d(0, 0, 0);
  }
}

@include keyframes(bounceInUp) {
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  from {
    opacity: 0;
    transform: translate3d(0, 3000px, 0);
  }

  60% {
    opacity: 1;
    transform: translate3d(0, -20px, 0);
  }

  75% {
    transform: translate3d(0, 10px, 0);
  }

  90% {
    transform: translate3d(0, -5px, 0);
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

@include keyframes(linkWidth) {
  from {
    width: 0%;
  }

  to {
    width: 100%;
  }
}

@include keyframes(rotation) {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@include keyframes(scrollLeft) {
  0%, 5% {
    transform: translate3d(50%, 0, 0);
  }

  15%, 70% {
    opacity: 1;
  }

  0%, 85%, 100% {
    opacity: 0;
  }

  85%, 100% {
    transform: translate3d(-50%, 0, 0);
  }
}

.bounceInUp {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bounceInUp;
}

.bounceOutDown {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bounceOutDown;
}
