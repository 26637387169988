.solutions {
  margin: 200px 0;
  
  h3 {
    margin-bottom: 70px;
    text-align: right;

    @media (max-width: $md - 1px) {
      margin-bottom: 1em;
    }
  }

  .slick {
    &-slide {
      margin-left: 70px;
      img {
        transition: 0.3s ease-in-out 0.3s;
      }

      .testi_text {
        h6 {
          transition: 0.3s $easing 0.4s;
        }

        p {
          transition: 0.3s $easing 0.5s;
        }

        p.fade {
          transition: 0.3s $easing 0.6s;
        }
      }

      &:not(.slick-active) {
        img {
          opacity: 0;
          transform: translateY(-10%);
        }

        .testi_text {
          h6, p {
            opacity: 0;
            transform: translateY(10%);
          }
        }
      }
    }
  
    &-list {
      margin-left: -70px;
    }

    &-slider {
      padding-top: 60px;
    }
    
    &-arrow {
      position: absolute;
      top: 0;
      width: 34px;
      height: 30px;
      cursor: pointer;
      font-size: 0;
      padding: 0;
      margin: 0;
      border: 0;
      background: transparent;
      @include bgContain;
      opacity: 0.3;
      transition: 0.15s ease-in-out;

      &:hover {
        opacity: 1;
      }

      &.slick-disabled {
        opacity: 0.1;
        cursor: not-allowed;
      }
    }

    &-prev {
      right: 64px;
      background-image: url(../img/testi-left.svg);
    }

    &-next {
      right: 0;
      background-image: url(../img/testi-right.svg);
    }
  }

  // .target {
  //   > h3, .solutions_slides {
  //     opacity: 0;
  //     transform: translateY(20px);
  //   }

  //   &.trigger {
  //     > h3, .solutions_slides {
  //       opacity: 1;
  //       transform: translateY(0);
  //     }

  //     > h3 {
  //       transition: 0.7s $easing;
  //     }

  //     .solutions_slides {
  //       transition: 0.7s $easing 0.2s;
  //     }
  //   }
  // }
}