section.gallery {
  margin-bottom: 130px;

  .gallery_name_holder {
    position: relative;
    display: inline-block;
    margin-bottom: 85px;

    @media screen and (min-width: 992px) {
      min-width: 450px;
    }

    @media screen and (max-width: 992px) {
      margin-bottom: 35px;
    }
  }
  .gallery_dots {
    position: absolute;
    right: -40px;
    top: 0px;

    @media screen and (max-width: 992px) {
      top: -100px;
      right: -200px;
    }
  }
  .gallery_holder {
    margin-top: 85px;
    display: flex;
    justify-content: flex-end;
  }
  .info_slide {
    padding: 0 7px;
    flex-shrink: 0;
    width: 100%;
  }
  .name {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }
  .buttons {
    display: flex;
    margin-left: -16px;
  }
  .swiper-container {
    margin: 0;
  }
  .info_holder {
    max-width: 342px;
    width: 100%;
    overflow: hidden;
    user-select: none;
    display: block;
    margin-left: -7px;

    @media screen and (max-width: 992px) {
      margin-bottom: 35px;
    }
  }
  .gallery_back,
  .gallery_forward {
    cursor: pointer;
    user-select: none;
    padding: 20px 16px;
    display: inline-flex;
    transition: opacity 250ms;
    opacity: 0.4;

    svg {
      display: block;
      margin: auto;
      width: 46px;
      height: 9px;
    }
    &:hover {
      opacity: 1;
    }
    &.disabled {
      pointer-events: none;
    }
  }
  .bottom_buttons {
    .gallery_back,
    .gallery_forward {
      opacity: 1;

      &:hover {
        opacity: 0.8;
      }
    }
  }
  .description {
    font-size: 14px;
    line-height: 160%;
  }
  .gallery_slider_holder {
    display: flex;
    position: absolute;
    top: 0;
    width: 100%;
    justify-content: flex-end;
    height: 100%;

    @media screen and (max-width: 992px) {
      position: static;
      order: 1;
      margin: 30px 0;
    }
  }
  .subsections {
    position: relative;
    height: 600px;

    @media screen and (max-width: 992px) {
      height: auto;
      display: flex;
      flex-direction: column;

      .container {
        order: 2;
      }
    }
  }
  .gallery_slider {
    width: calc(((100% - 1366px) / 2) + 820px);
    height: 100%;
    padding-right: 65px;

    @media screen and (max-width: 1366px) {
      width: calc(100% - ((100% - 100px) * 0.07) - 450px);
    }
    @media screen and (max-width: 992px) {
      width: 100vw;
      height: calc(100vw - 70px);
      padding-right: 35px;
      padding-left: 35px;
    }
    .swiper-wrapper {
      will-change: transform;
      cursor: grab;
      transition-timing-function: cubic-bezier(0.31, 0.6, 0.38, 1) !important;
    }
  }
  .gallery_slide {
    transform: scale(0.9);
    opacity: 0.7;
    transition: transform 1.5s ease-in-out, opacity 1.5s ease-in-out;
    will-change: transform, opacity;
    height: 100%;

    &.swiper-slide-active {
      transform: scale(1);
      opacity: 1;
    }
    img {
      display: block;
      width: 100%;
      height: 100%;
      object-position: center;
      object-fit: cover;
    }
  }
  .bottom_buttons {
    position: absolute;
    z-index: 2;
    bottom: 20px;
    width: calc(((100% - 1366px) / 2) + 800px);
    right: 0;

    svg,
    svg * {
      fill: #fff;
      stroke: #fff;
    }
    @media screen and (max-width: 1366px) {
      width: calc(100% - ((100% - 100px) * 0.07) - 480px);
    }
    @media screen and (max-width: 992px) {
      display: none;
    }
  }
}
