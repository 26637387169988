.outdated {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 40;
  width: 100%;
  height: 100%;
  background-color: $blue;
  background-color: rgba($blue, 0.7);
  display: none;
  padding: 30px;

  .container {
    height: 100%;
    display: table;

    .block {
      display: table-cell;
      vertical-align: middle;
      text-align: center;

      .white {
        background-color: $white;
        display: inline-block;
        max-width: 350px;
        width: 100%;
        padding: 40px 35px;

        img {
          display: inline-block;
        }

        h6, p, .btn {
          margin-top: 1em;
        }
      }
    }
  }
}