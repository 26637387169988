
.heroSlider {
  position: static;
  margin: 200px 0;

  .hero_dots {
    position: absolute;
    z-index: 0;
    bottom: auto;
    top: calc(100% - 66px);
    right: calc(31.6% - 128px);

    @media (max-width: $md - 1px) {
      top: 23px;
      left: 26px;
      right: auto;

      .hoverdots {
        width: 176px;
        height: 176px;

        &_dot {
          width: 12.5%;
          padding-bottom: 12.5%;

          &:nth-child(12n), &:nth-child(12n-1), &:nth-child(12n-2), &:nth-child(12n-3), &:nth-child(96) ~ .hoverdots_dot {
            display: none;
          }
        }
      }
    }
  }

  &_wrapper {
    position: relative;
    background-color: $blue;
  }

  &_slides {
    left: 0;
    top: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    overflow: hidden;
  }

  &-fakes {
    width: 100%;
    height: 100%;
    display: flex;
    position: relative;
    overflow: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: -1;

    .fakeSlide {
      width: 100%;
      flex-shrink: 0;
    }
  }

  &_slide {
    width: 100%;
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    pointer-events: none;

    & > a {
      width: 100%;
    }

    .hero_bg {
      position: absolute;
      top: 0;
      left: em(-40px);
      width: calc(100% + #{em(80px)});
      height: 100%;
      transform: translate3d(0, 0, 0);
      opacity: 0;
    }

    .hero_text {
      transform: translate3d(0, 0, 0);
      opacity: 0;
      pointer-events: none;
    }

    &:not(.done) {
      .hero_bg {
        transform: translate3d(#{em(40px)}, 0, 0);
      }

      .hero_text {
        transform: translate3d(#{em(40px)}, 0, 0);
      }
    }

    &.done {
      .hero_bg {
        transform: translate3d(#{em(-40px)}, 0, 0);
      }

      .hero_text {
        transform: translate3d(#{em(-40px)}, 0, 0);
      }
    }

    &.active {
      pointer-events: auto;
      transform: translate3d(0, 0, 0);
      z-index: 2;

      .hero_bg {
        transition: 1s $easing 0.5s;
        transform: translate3d(0, 0, 0);
        opacity: 1;
      }

      .hero_text {
        transition: 0.8s $easing 1.2s;
        transform: translate3d(0, 0, 0);
        opacity: 1;
        pointer-events: auto;
      }

      &.moving {
        pointer-events: none;
        z-index: 3;
      }

      &.right {
        .hero_bg, .hero_text {
          transform: translate3d(#{em(-40px)}, 0, 0);
        }

        .hero_text {
          opacity: 0;
          transition: 0.8s $easing;
        }
      }

      &.left {
        .hero_bg, .hero_text {
          transform: translate3d(#{em(40px)}, 0, 0);
        }

        .hero_text {
          opacity: 0;
          transition: 0.8s $easing;
        }
      }
    }
  }

  &_controls {
    .container {
      position: relative;
    }

    .wrap {
      height: 170px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      @media (max-width: $md - 1px) {
        height: 140px;
      }
    }

    .scrollDown {
      margin-left: -20px;

      @media (max-width: $md - 1px) {
        width: 100px;
      }

      .inner {
        display: inline-block;
        cursor: pointer;
        color: $blue;
        padding: 20px;
        position: relative;
        z-index: 1;

        .svg, small {
          display: block;
        }

        .svg {
          width: 18px;
          height: 32px;
          animation: bounceUpDown 1s linear 0s infinite alternate;

          path {
            fill: currentColor;
          }
        }

        small {
          margin-top: 10px;
          font-size: 12px;
          line-height: 20px;
          font-weight: 700;
          text-transform: uppercase;
          letter-spacing: 0.12em;
        }
      }
    }
  }

  &_dots {
    position: absolute;
    width: 31.6%;
    right: 0;
    padding: 0 40px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 2;

    @media (max-width: $md - 1px) {
      width: calc(100% - 135px);
      max-width: 240px;
      padding: 0 10px;
    }

    &::before {
      content: '';
      z-index: -1;
      position: absolute;
      left: 0;
      top: 0;
      width: 100vw;
      height: 100%;
      background-color: $blue;
    }

    ul {
      margin-right: 0;
      width: calc(100% - 40px);
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: $md - 1px) {
        width: 100%;
      }
    }
  }

  &_dot {
    button {
      display: block;
      position: relative;
      background-color: transparent;
      color: $white;
      border: 0;
      font-family: $font;
      font-weight: 700;
      font-size: 24px;
      line-height: 30px;
      padding: 0 20px;
      opacity: 0.3;
      transition: 0.3s $easing;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        width: 0;
        left: 20px;
        bottom: 0;
        background-color: currentColor;
        height: 2px;
        transition: 0.3s $easing;
      }

      &:hover {
        opacity: 1;
      }
    }

    &.active {
      button {
        opacity: 1;

        &::before {
          width: calc(100% - 40px);
        }
      }
    }
  }
}