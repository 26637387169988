.addons {
  .wrap {
    position: relative;
  }

  &_wrap {
    padding-left: 25%;

    @media (max-width: $md - 1px) {
      padding: 0;
    }

    &-switchable {
      padding-left: 9%;

      @media (max-width: $md - 1px) {
        padding: 0;
      }
    }

    &:not(.addons_wrap-switchable) {
      .addons_color {
        &::after {
          content: '';
          position: absolute;
          height: 330px;
          width: 20%;
          right: 100%;
          bottom: 0;
          background-color: $blue;
        }

        &-white {
          &::after {
            background-color: $white;
          }
        }
      }
    }
  }

  &_decors {
    position: absolute;
    left: 0;
    bottom: 264px;

    @media (max-width: $md - 1px) {
      right: 0;
      left: auto;
      top: -110px;

      .hoverdots {
        width: 176px;
        height: 176px;

        &_dot {
          width: 12.5%;
          padding-bottom: 12.5%;

          &:nth-child(12n), &:nth-child(12n-1), &:nth-child(12n-2), &:nth-child(12n-3), &:nth-child(96) ~ .hoverdots_dot {
            display: none;
          }
        }
      }
    }
  }

  &_color {
    position: relative;
    color: $white;
    padding: 10% 10% 13% 18%;

    @media (max-width: $md - 1px) {
      padding: 80px 0;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100vw;
      background-color: $blue;
      z-index: -1;

      @media (max-width: $md - 1px) {
        left: calc(50% - 50vw);
      }
    }

    &:not(.addons_color-white) li::before {
      background-color: currentColor;
    }

    &-white {
      color: $black;

      &::before {
        background-color: $white;
      }

      .addons_item .icon {
        color: $black;
      }

      + .addons_decors {
        right: 0;
        left: auto;
        top: -88px;

        @media (max-width: $md - 1px) {
          .hoverdots {
            width: 176px;
            height: 176px;

            &_dot {
              width: 12.5%;
              padding-bottom: 12.5%;

              &:nth-child(12n), &:nth-child(12n-1), &:nth-child(12n-2), &:nth-child(12n-3), &:nth-child(96) ~ .hoverdots_dot {
                display: none;
              }
            }
          }
        }
      }
    }

    h3 {
      margin-bottom: 70px;

      @media (max-width: $md - 1px) {
        margin-bottom: 1em;
      }
    }
  }

  &_switchables {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &_item {
    @media (min-width: $md) {
      width: calc(#{percentage(1/3)} - 60px);
    }

    .icon {
      color: $white;
    }
  }

  &_toggles {
    width: calc(50% - 40px);
    margin-top: -15px;
    position: relative;

    @media (max-width: $md - 1px) {
      width: calc(100% + 70px);
      margin-left: -35px;
      margin-bottom: 30px;
      flex-shrink: 0;

      &-wrap {
        padding: 0 35px;
      }

      form {
        display: flex;
        align-items: center;
        justify-content: flex-start;
      }

      &::before, &::after {
        content: '';
        width: 35px;
        position: absolute;
        top: 0;
        height: 100%;
        z-index: 3;
        pointer-events: none;
      }

      &::before {
        background-image: $bfhrevgrad;
        left: 0;
      }

      &::after {
        right: 0;
        background-image: $bfhgrad;
      }
    }

    .ps {
      &__thumb {
        &-x, &-y {
          background-color: $blue !important;
        }

        &-x {
          height: 0px !important;
        }
      }

      &__rail {
        &-x, &-y {
          background-color: transparent !important;
        }

        &-x {
          height: 0px !important;
        }
      }
    }

    .input {
      @media (max-width: $md - 1px) {
        flex-shrink: 0;

        &:not(:first-child) {
          margin-left: 30px;
        }
      }
      input {
        display: none;

        &:checked + label {
          opacity: 1;

          &::before {
            opacity: 1;
          }
        }
      }

      label {
        padding: 15px 0 15px 32px;
        position: relative;
        display: inline-block;
        cursor: pointer;
        line-height: 30px;
        font-family: $font;
        font-size: 18px;
        letter-spacing: 0.02em;
        transition: opacity 0.15s ease-in-out;
        opacity: 0.5;

        @media (max-width: $md - 1px) {
          white-space: nowrap;
          padding: 0 0 2px;
        }

        &::before {
          content: '';
          position: absolute;
          width: 2px;
          height: 30px;
          left: 0;
          top: 15px;
          background-color: currentColor;
          opacity: 0;
          pointer-events: none;
          transition: opacity 0.15s ease-in-out;

          @media (max-width: $md - 1px) {
            height: 2px;
            width: 100%;
            top: auto;
            bottom: 0;
          }
        }

        &:hover {
          &::before {
            opacity: 1;
          }
        }
      }
    }
  }

  &_texts {
    position: relative;
    transition: height 0.6s $easing;
    width: 50%;

    @media (max-width: $md - 1px) {
      max-width: 496px;
      width: 100%;
    }
  }

  &_text {
    position: absolute;
    top: 0;
    left: 100vw;
    width: 100%;
    pointer-events: none;
    transform: translateY(50px);
    opacity: 0;
    transition: left 0.001ms linear 0.6s, opacity 0.6s $easing, transform 0.6s $easing;

    &.active {
      left: 0;
      pointer-events: auto;
      transform: translateY(0);
      opacity: 1;
      transition: left 0.001ms linear 0.6s, opacity 0.6s $easing 600.001ms, transform 0.6s $easing 600.001ms;
    }

    ul {
      font-size: 14px;
      line-height: 24px;
      margin: 2em 0;
    }

    p {
      margin: 1em 0;
    }

    .btn {
      margin: 1em 0;

      @media (max-width: $md - 1px) {
        max-width: 400px;
        width: 100%;
      }
    }

    ul, p, .btn {
      &:first-child {
        margin-top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    li {
      padding-left: 20px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        top: 10px;
        left: 0;
        background-color: currentColor;
        border-radius: 50%;
      }
    }
  }

  // .target {
  //   .addons {
  //     &_color {
  //       &::before, &::after {
  //         transform-origin: right center;
  //         transform: scaleX(0);
  //       }

  //       &::after {
  //         transform: scaleX(0);
  //         right: calc(100% - 50px);
  //       }

  //       > * {
  //         opacity: 0;
  //         transform: translateY(20px);
  //       }
  //     }

  //     &_decors {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }
  //   }

  //   &.trigger {
  //     .addons {
  //       &_color {
  //         &::before, &::after {
  //           transform: scaleX(1);
  //         }

  //         &::before {
  //           transition: 0.7s $easing;
  //         }

  //         &::after {
  //           transition: 0.2s $easing 0.7s;
  //           right: 100%;
  //         }

  //         > * {
  //           opacity: 1;
  //           transform: translateY(0);
  //           transition: 0.7s $easing 0.9s;
  //         }
  //       }

  //       &_decors {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing 1.1s;
  //       }
  //     }
  //   }
  // }
}