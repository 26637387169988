.order {
  margin: 100px 0 150px;
  padding: 100px 0 50px;
  background-color: $white;

  @media (max-width: $md - 1px) {
    padding: 50px 0;
    margin: 100px 0;
  }

  &_table {
    width: 100%;
    overflow: hidden;
    position: relative;
    z-index: 1;
    margin-top: 40px;
    min-width: 992px;

    @media (max-width: $md - 1px) {
      margin-top: 20px;
    }

    &-wrap {
      @media (max-width: $md - 1px) {
        width: calc(100% + 70px);
        margin-left: -35px;
        padding: 0 35px;
      }
    
      .ps {
        &__thumb {
          &-x, &-y {
            background-color: $blue !important;
          }

          &-x {
            height: 0px !important;
          }
        }

        &__rail {
          &-x, &-y {
            background-color: transparent !important;
          }

          &-x {
            height: 0px !important;
          }
        }
      }
    }

    thead {
      th {
        font-size: 24px;
        font-weight: 700;
        font-family: $font;
        letter-spacing: 0.01em;
        padding: 70px 20px 30px;

        &:not(:first-child) {
          width: 200px;
        }

        &.recom {
          position: relative;
  
          &::before {
            content: '';
            background-color: $dwhite;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 1000vh;
            z-index: -1;
          }

          &::after {
            content: attr(data-recom);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            font-size: 14px;
            line-height: 24px;
            padding: 8px;
            background-color: $blue;
            color: $white;
          }
        }

        &.custom {
          position: relative;
  
          &::before {
            content: '';
            background-color: rgba($color: $blue, $alpha: .1);
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 1000vh;
            z-index: -1;
          }

          &::after {
            content: attr(data-recom);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            font-size: 14px;
            line-height: 24px;
            padding: 8px;
            background-color: $white;
            color: $white;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          font-size: 14px;
          line-height: 24px;
          padding: 13px 20px;

          &.custom {
            color: $blue;
          }

          &.no, &.yes {
            position: relative;

            &::after {
              content: '';
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
              height: 17px;
              @include bgContain;
            }
          }

          &.no {
            &::after {
              background-image: url(../img/no.svg);
              width: 13px;
            }
          }

          &.yes {
            &::after {
              background-image: url(../img/yes.svg);
              width: 16px;
            }
          }

          &:not(:first-child) {
            text-align: center;
          }
        }

        &:not(.sub) {
          border-top: 2px solid transparent;
          position: relative;
          transform: translateY(0);

          td:first-child {
            &::before {
              content: '';
              position: absolute;
              left: 0;
              top: 0;
              width: 100%;
              height: 2px;
              background-color: $dwhite;
            }
          }
        }
  
        &.sub {
          td:first-child {
            padding-left: 40px;
            position: relative;
  
            &::before {
              content: '';
              position: absolute;
              left: 20px;
              top: 23px;
              width: 4px;
              height: 4px;
              border-radius: 50%;
              background-color: $blue;
            }
          }
        }
  
        &.price {
          td:first-child {
            color: rgba($black, 0.5);
            padding-top: 50px;
            vertical-align: top;
          }

          td {
            padding: 30px 20px;
            vertical-align: bottom;
          }

          .price_text {
            font-size: 42px;
            line-height: 54px;
            letter-spacing: 0.02em;
            font-weight: 700;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-bottom: 10px;

            &::before {
              font-size: 18px;
              line-height: 24px;
              height: 54px;
              font-weight: 700;
              content: attr(data-prefix);
              margin-right: 3px;
            }

            &::after {
              font-size: 14px;
              line-height: 24px;
              font-weight: 400;
              opacity: 0.5;
              content: attr(data-postfix);
              margin-left: 1px;
            }

            &__custom {
              font-size: 29px;
              font-weight: bold;
              letter-spacing: .02em;
              line-height: 32px;

              &::after, &::before {
                content: "";
              }
            }
          }

          .btn {
            min-width: 0;
            width: 100%;
          }
        }
      }
    }

    &__mob {
      min-width: unset;

      thead {

        th {
          background-color: $blue;
          color: $white;
          font-size: 18px;
          letter-spacing: .01em;
          line-height: 24px;
          padding: 15px 78px;

          &.recom {

            &::before {
              content: "";
            }

            &::after {
              background-color: $white;
              color: $blue;
              font-size: 9px;
              line-height: 9px;
              left: auto;
              transform: rotate(45deg);
              right: -25px;
              top: 20px;
              z-index: 1;
              width: auto;
              min-width: 120px;
            }
          }
        }
      }

      tbody {

        tr {

          td {
            width: 50%;
            padding: 5px 0px;

            &:not(:first-child) {
              vertical-align: middle;
            }
          }

          &.sub {

            td {

              &:first-child {
                padding-left: 20px;

                &::before {
                  left: 0px;
                  top: 14px;
                }
              }
            }
          }

          &:first-child {

            td {
              padding-top: 14px;
            }
          }
        }
      }
    }

  }

  &_mobile {

    &__title {
      margin-bottom: 40px;
    }
  }


  &_item {

    &__content {
      padding-bottom: 24px;
      overflow: hidden;
      max-height: 230px;
      transition: max-height 0.5s ease-in-out;
    }

    &__toggler {
      padding-bottom: 40px;
      background-color: $white;
      position: relative;
      transition: background-color 0.5s ease-in-out;

      &::after {
        content: "";
        position: absolute;
        top: -50px;
        left: 0px;
        width: 100%;
        height: 50px;
        z-index: 1;
        background: rgb(255,255,255);
        background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 95%);
      }

      &.active {
        background-color: transparent;

        &::after {
          background: none;
        }

        .order_item__toggler_btn {

          & > div {
            &::after {
              content: attr(data-opened);
            }
          }

          svg {
            transform: rotate(180deg);
          }
        }
      }

      &_wrap {
        width: 50%;
      }

      &_upper {
        display: flex;

        .price_text {
          font-size: 42px;
          line-height: 42px;
          letter-spacing: 0.02em;
          font-weight: 700;
          display: flex;
          align-items: center;
          justify-content: left;
          margin-bottom: 0px;

          &::before {
            font-size: 18px;
            line-height: 24px;
            height: 42px;
            font-weight: 700;
            content: attr(data-prefix);
            margin-right: 3px;
          }

          &::after {
            font-size: 14px;
            line-height: 24px;
            font-weight: 400;
            opacity: 1;
            content: attr(data-postfix);
            margin-left: 1px;
          }

          &__custom {
            font-size: 29px;
            font-weight: bold;
            letter-spacing: .02em;
            line-height: 32px;

            &::after, &::before {
              content: "";
            }
          }
        }

        .price_subtext {
            font-size: 13px;
            opacity: .5;
        }

        .btn {
          min-width: 100%;
        }
      }

      &_btn {
        margin-top: 20px;
        height: 16px;

        & > div {
          position: relative;

          &::after {
            content: attr(data-closed);
            position: absolute;
            font-size: 14px;
            color: $blue;
            line-height: 18px;
            left: 20px;
          }
        }

        svg {
          margin-right: 16px;
          width: 7px;
          height: 16px;
          transition: .3s ease-in-out;
        }
      }
    }
  }

  // &.target {
  //   background-color: transparent;

  //   .wrap {
  //     > h3 {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }

  //     .order_table {
  //       thead tr {
  //         opacity: 0;
  //         transform: translateY(20px);

  //         .recom {
  //           &::after {
  //             opacity: 0;
  //             transform: translateY(-20px);
  //           }

  //           &::before {
  //             opacity: 0;
  //           }
  //         }
  //       }
        
  //       tbody {
  //         tr {
  //           opacity: 0;
  //           transform: translateY(20px);
  //         }
  //       }
  //     }
  //   }

  //   &.trigger {
  //     background-color: $white;
  //     transition: 0.7s $easing;

  //     .wrap {
  //       > h3 {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing 0.2s;
  //       }

  //       .order_table {
  //         thead tr {
  //           opacity: 1;
  //           transform: translateY(0);
  //           transition: 0.7s $easing 0.4s;
  
  //           .recom {
  //             &::after {
  //               opacity: 1;
  //               transform: translateY(0);
  //               transition: 0.7s $easing 0.6s;
  //             }
  
  //             &::before {
  //               opacity: 1;
  //               transition: 0.7s $easing 0.7s;
  //             }
  //           }
  //         }
        
  //         tbody {
  //           tr {
  //             opacity: 1;
  //             transform: translateY(0);
  //             transition: 0.7s $easing;

  //             @for $k from 1 to 30 {
  //               &:nth-child(#{$k}) {
  //                 $delay: 0.2s + (0.2s * $k);
  //                 transition-delay: $delay;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}