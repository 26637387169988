.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
  display: none;

  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 200%;
    background-color: $white;
    z-index: -1;
  }

  &_outer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    position: relative;
  }

  &_close {
    position: absolute;
    top: 0;
    right: 0;
    padding: 35px 50px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $blue;
    cursor: pointer;
    z-index: 5;

    @media (max-width: $md - 1px) {
      padding: 23px 35px;
    }

    &:hover {
      opacity: 0.5;
    }

    small {
      font-size: 12px;
      letter-spacing: 0.12em;
      line-height: 16px;
      font-weight: 700;
      text-transform: uppercase;

      @media (max-width: $md - 1px) {
        display: none;
      }
    }

    &-cross {
      width: 24px;
      height: 24px;
      display: block;
      position: relative;
      margin-left: 15px;

      &::before, &::after {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform-origin: center;
        width: 30px;
        height: 2px;
        background-color: currentColor;
      }

      &::before {
        transform: translate3d(-50%, -50%, 0) rotate(-45deg);
      }

      &::after {
        transform: translate3d(-50%, -50%, 0) rotate(45deg);
      }
    }
  }

  &_inner {
    max-height: 100%;
    width: 100%;
    overflow: auto;
    padding: 50px 0;

    .container {
      display: flex;

      .form {
        margin: 0 auto;
        width: 100%;
        max-width: 540px;
      }
    }
  }

  &#search {
    &::before {
      background-color: $blue;
    }

    .modal {
      &_close {
        color: $white;
      }

      &_suggests {
        position: absolute;
        bottom: 50px;
        left: 0;
        width: 100%;

        @media (max-width: $md - 1px) {
          bottom: 40px;
        }

        p {
          color: $white;
          padding: 0 50px;

          @media (max-width: $md - 1px) {
            padding: 0;
          }

          span {
            opacity: 0.5;
            cursor: pointer;
            display: inline-block;
            margin-left: 20px;
            transition: 0.2s $easing;

            @media (max-width: $md - 1px) {
              margin-left: 0;
              display: block;
            }

            &:hover {
              opacity: 1;
            }
          }
        }
      }
      
      &_inner {
        .container .form {
          max-width: none;

          &_input {
            position: relative;

            &::before {
              content: "";
              position: absolute;
              left: 0;
              width: 50px;
              height: 70px;
              top: 0;
              background-image: url(../img/search.svg);
              background-size: 24px 24px;
              background-repeat: no-repeat;
              background-position: center left;
              opacity: 0.3;

              @media (max-width: $md - 1px) {
                width: 35px;
                height: 50px
              }
            }

            input {
              border-bottom: 2px solid rgba($black, 0.3);
              font-size: 38px;
              font-weight: 700;
              color: $white;
              height: 72px;
              padding-left: 50px;

              @media (max-width: $md - 1px) {
                font-size: 24px;
                height: 50px;
                padding-left: 35px;
              }
            }

            label {
              color: rgba($black, 0.3);
              font-size: 38px;
              font-weight: 700;
              opacity: 1;
              line-height: 54px;
              padding-bottom: 9px;
              padding-left: 50px;

              @media (max-width: $md - 1px) {
                font-size: 24px;
                line-height: 30px;
                padding-bottom: 10px;
                padding-left: 35px;
              }
            }

            &.focus input, &.full input, input:focus {
              & + label {
                padding-bottom: 60px;
                font-size: 14px;
                line-height: 20px;

                @media (max-width: $md - 1px) {
                  padding-bottom: 42px;
                }
              }
            }
          }
        }
      }
    }
  }

  &__brochure {

    &::before {
      background-color: rgba($color: #2522BF, $alpha: .7);
    }

    .modal_inner {
      position: relative;
      background-color: $white;
      max-width: 1000px;
      margin: 0px auto;
      padding-top: 130px;
      padding-bottom: 126px;
    }
  }
}