.blog_filter {
    display: flex;

    &__title {
        margin-right: 90px;
        margin-top: 2px;
        font-size: 14px;
        font-weight: bold;
        line-height: 18px;
        letter-spacing: .01em;
        text-transform: uppercase;
        color: $blue;
    }

    &__menu {
        list-style: none;
        
        li {
            display: inline-block;
            font-size: 14px;
            font-weight: bold;
            position: relative;
            margin-bottom: 15px;

            &:not(:last-child) {
                margin-right: 25px;
            }

            a {
                position: relative;
                transition: .3s ease-in-out;

                &::before {
                    content: "";
                    position: absolute;
                    width: 100%;
                    height: 2px;
                    background-color: $blue;
                    bottom: -5px;
                    transform-origin: center center;
                    transform: scaleX(0) translate3d(0,100%,0);
                    transition: .3s ease-in-out;
                }
            }

            &:hover {

                a {
                    color: $blue;

                    &::before {
                        transform: scaleX(1) translate3d(0,100%,0);
                    }
                }
            }
        }
    }

}

.filter_submenu {
    position: absolute;
    background-color: $white;
    padding: 34px 34px 4px;
    width: 346px;
    box-sizing: border-box;
    top: 25px;
    left: 0px;
    z-index: 3;
    opacity: 0;
    transform: translateY(20px);
    transition: .2s ease-in-out;
    visibility: hidden;

    &__checkboxes {
        display: flex;
        flex-wrap: wrap;
    }

    &__submit {
        padding-bottom: 34px;

        .link {
            border: none;
            font-size: 14px;
            font-family: $font;
            line-height: 18px;
            font-weight: bold;
        }
    }

    &__item {
        width: calc((346px - 72px) / 2);
        margin-bottom: 25px;

        label {
            line-height: 15px;
            padding-top: 9px;
        }
    }
}

.blog_filter__menu {

    > li:hover {

        .filter_submenu {
            opacity: 1;
            transform: translateY(0px);
            visibility: visible;
        }
    }
}

.mobileBlogFilter {
    padding-top: 40px;
}