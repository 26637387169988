.icons {
  margin: 150px 0;

  &-offset {
    .wrap {
      position: relative;
      left: 7%;

      @media (max-width: $md - 1px) {
        left: 0;
      }
    }
  }

  .wrap {
    width: 80%;

    @media (max-width: $md - 1px) {
      width: 100%;
    }
  }

  h3 {
    margin-bottom: 70px;

    @media (max-width: $md - 1px) {
      margin-bottom: 1em;
    }
  }

  &_list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -30px -30px 0;

    @media (max-width: $md - 1px) {
      margin: -20px -20px 0;
    }

    @media (max-width: $sm - 1px) {
      margin: -10px -10px 0;
    }
  }

  &_item {
    width: calc(25% - 60px);
    margin: 30px;

    @media (max-width: $md - 1px) {
      width: calc(#{percentage(1/3)} - 40px);
      margin: 20px;
    }

    @media (max-width: $sm - 1px) {
      width: calc(50% - 20px);
      margin: 10px;
    }

    .icon {
      height: 60px;
      width: 100%;
      margin-bottom: 30px;
      color: $blue;

      svg, img {
        height: 100%;
        max-width: 100%;

        path {
          fill: currentColor;
        }
      }
    }
  }

  // .target {
  //   > h3 {
  //     opacity: 0;
  //     transform: translateY(20px);
  //   }

  //   .icons_item {
  //     .icon, p {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }
  //   }

  //   &.trigger {
  //     > h3 {
  //       opacity: 1;
  //       transform: translateY(0);
  //       transition: 0.7s $easing;
  //     }

  //     .icons_item {
  //       .icon, p {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing;
  //       }

  //       @for $k from 1 to 30 {
  //         &:nth-child(#{$k}) {
  //           $delay: 0.2s + (0.2s * $k);

  //           .icon {
  //             transition-delay: $delay;
  //           }

  //           p {
  //             transition-delay: $delay + 0.2s;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}