.bg {
  z-index: -1;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  .block {
    position: absolute;
    transform: translate3d(0, 0, 0);
    transition: 1s ease-in-out;
  }

  &[which="none"] {
    .block {
      left: 0%;
      bottom: 0%;
      width: 50%;
      height: 50%;
      background-color: transparent;
    }
  }

  &:not([which="none"]) {
    .block {
      background-color: $white;
    }
  }

  &[which="1"] {
    .block {
      &:nth-child(1) {
        left: 0%;
        bottom: 16%;
        width: 42%;
        height: 84%;
      }

      &:nth-child(2), &:nth-child(3) {
        left: 30%;
        bottom: 0%;
        height: 65%;
        width: 39%;
      }
    }
  }

  &[which="2"] {
    .block {
      &:nth-child(1) {
        left: 0%;
        bottom: 0%;
        width: 100%;
        height: 12%;
      }

      &:nth-child(2) {
        left: 26%;
        bottom: 0%;
        height: 48%;
        width: 34%;
      }

      &:nth-child(3) {
        left: 43%;
        bottom: 0%;
        height: 84%;
        width: 57%;
      }
    }
  }

  &[which="3"] {
    .block {
      &:nth-child(1) {
        left: 0%;
        bottom: 36%;
        width: 42%;
        height: 64%;
      }

      &:nth-child(2), &:nth-child(3) {
        left: 0%;
        bottom: 0%;
        height: 42%;
        width: 29%;
      }
    }
  }

  &[which="4"] {
    .block {
      &:nth-child(1) {
        left: 64%;
        bottom: 0%;
        width: 36%;
        height: 100%;
      }

      &:nth-child(2), &:nth-child(3) {
        left: 53%;
        bottom: 0%;
        height: 60%;
        width: 23%;
      }
    }
  }

  &[which="5"] {
    .block {
      left: 0%;
      bottom: 0%;
      height: 60%;
      width: 30%;
    }
  }

  &[which="6"] {
    .block {
      &:nth-child(1) {
        left: 43%;
        bottom: 0%;
        width: 57%;
        height: 84%;
      }

      &:nth-child(2), &:nth-child(3) {
        left: 23%;
        bottom: 0%;
        height: 48%;
        width: 32%;
      }
    }
  }

  &[which="7"] {
    .block {
      &:nth-child(1) {
        left: 0%;
        bottom: 16%;
        width: 32%;
        height: 84%;
      }

      &:nth-child(2), &:nth-child(3) {
        left: 16%;
        bottom: 0%;
        height: 50%;
        width: 40%;
      }
    }
  }

  &[which="8"] {
    .block {
      &:nth-child(1) {
        left: 0%;
        bottom: 0%;
        width: 29%;
        height: 100%;
      }

      &:nth-child(2), &:nth-child(3) {
        left: 0%;
        bottom: 50%;
        height: 50%;
        width: 40%;
      }
    }
  }

  &[which="foot"] {
    .block {
      left: 50%;
      bottom: 0%;
      height: 60%;
      width: 30%;

      @media (min-width: $lg) {
        width: 400px;
      }
    }
  }
}