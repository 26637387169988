.heroSlider.blogSlider {
    margin-bottom: 100px;

    .hero_dots {
        top: calc(100% - 320px);
        right: calc(31.6% - 195px);
    }

    .hero_text {
        max-height: calc(100vh - 170px);
    }
}

.hero_text {

    &__title {
        font-size: 78px;
        line-height: 84px;
        letter-spacing: .02em;
        font-weight: bold;
        color: $white;
        margin-bottom: 25px;

        @media screen and (max-height: 660px) {
            font-size: 40px;
            line-height: 54px;
            margin-bottom: 30px;
        }
    }

    &__author {
        font-size: 18px;
        line-height: 24px;
        letter-spacing: .024em;
        font-weight: bold;
        margin-bottom: 20px;

        @media screen and (max-height: 660px) {
            font-size: 16px;
            margin-bottom: 15px;
        }
    }

    &__text {
        font-size: 18px;
        margin-bottom: 20px;

        @media screen and (max-height: 660px) {
            font-size: 14px;
            margin-bottom: 15px;
        }
    }

    &__date {
        font-size: 14px;
        margin-bottom: 40px;

        @media (max-width: $md - 1px) {
            margin-bottom: 30px;
        }
    }

    &__catLink {
        letter-spacing: .01em;
        line-height: 27px;
        margin-right: 21px;

        @media (max-width: $md - 1px) {
            display: block;
        }
    }
}

.blog_list {
    margin-bottom: 150px;
}

.blog_block {

    &__content, &__img {
        width: 50%;
    }

    &__content {
        padding-right: calc(50% / 6);
        order: 1;

        @media (max-width: $md - 1px) {
            padding-right: 0;
        }
    }

    &__title {
        font-size: 27px;
        line-height: 32px;
        margin-bottom: 15px;

        @media (max-width: $md - 1px) {
            font-size: 24px;
            line-height: 32px;
        }
    }

    &__topic {
        color: $blue;
        margin-bottom: 43px;

        @media (max-width: $md - 1px) {
            margin-bottom: 30px;
        }
    }

    &__name {
        font-size: 14px;
        line-height: 24px;
        letter-spacing: .024em;
        font-weight: bold;
        margin-bottom: 23px;
    }

    &__exerp {
        margin-bottom: 42px;

        @media (max-width: $md - 1px) {
            margin-bottom: 30px;
        }
    }

    &__img {
        order: 2;
        position: relative;
        overflow: hidden;

        @media (max-width: $md - 1px) {
            margin-bottom: 30px;
        }

        &_inner {
            @include bgCover();
            position: absolute;
            height: 100%;
            width: 100%;
            transform-origin: center center;
            transform: scale(1);
            transition: .3s cubic-bezier(0.215, 0.610, 0.355, 1);
        }

        &:hover {

            .blog_block__img_inner {
                transform: scale(1.05);
            }
        }

        a {
            display: block;
            height: 100%;
            width: 100%;
            position: relative;
        }

        .date {
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: $blue;
            color: $white;
            font-size: 14px;
            line-height: 24px;
            padding: 9px 18px;
            letter-spacing: .02em;
            font-weight: 700;
            z-index: 1;
        }
    }

    &:not(:last-child) {
        margin-bottom: 140px;

        @media (max-width: $md - 1px) {
            margin-bottom: 50px;
        }
    }

    &:nth-child(even) {

        .blog_block__content {
            order: 2;
            padding-right: 0px;
            padding-left: calc(50% / 6);
        }

        .blog_block__img {
            order: 1;
        }


    }

    @media (max-width: $md - 1px) {
        display: block;

        .flexWrap {
            display: block;

            .blog_block__img {
                width: calc(100% + 34px);
                height: 254px;
                order: 1;
            }

            .blog_block__content {
                order: 2;
                width: 100%;
                padding-left: 0;
            }
        }
    }
}

