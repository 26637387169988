.quote {
  margin: 150px 0;

  @media (max-width: $md - 1px) {
    margin: 100px 0;
  }

  &-decored {
    margin-bottom: 300px;

    @media (max-width: $md - 1px) {
      margin-bottom: 150px;
    }
  }

  &_wrap {
    position: relative;
    margin: 0 auto;
    max-width: 750px;
  }

  &_dots {
    position: absolute;
    right: -22px;
    bottom: -150px;

    @media (max-width: $md - 1px) {
      bottom: -100px;

      .hoverdots {
        width: 176px;
        height: 176px;
  
        &_dot {
          width: 12.5%;
          padding-bottom: 12.5%;
  
          &:nth-child(12n), &:nth-child(12n-1), &:nth-child(12n-2), &:nth-child(12n-3), &:nth-child(96) ~ .hoverdots_dot {
            display: none;
          }
        }
      }
    }
  }

  &_icon {
    svg, img {
      height: 54px;
      width: auto;
      margin: 0 auto 30px;
    }
  }

  &_text {
    h5 {
      font-weight: 400;
      text-align: center;
    }
  }

  // .target {
  //   .quote {
  //     &_icon, &_text, &_dots {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }
  //   }

  //   &.trigger {
  //     .quote {
  //       &_icon, &_text, &_dots {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing;
  //       }
      
  //       &_text {
  //         transition-delay: 0.2s;
  //       }

  //       &_dots {
  //         transition-delay: 0.4s;
  //       }
  //     }
  //   }
  // }
}

.quote__hasIcon {
  margin: 90px 0px 70px;


  &__title {
    font-size: 24px;
    letter-spacing: .01em;
    line-height: 28px;
    position: relative;
    z-index: 1;

    &::before {
      content: '';
      position: absolute;
      height: 70px;
      width: 70px;
      left: -20px;
      top: -45px;
      @include bgContain;
      background-image: url(../img/quotes.svg);
      opacity: 0.1;
      z-index: -1;
    }
  }

  &__text {
    font-size: 18px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: .01em;
  }

  & + section {
    margin-top: 70px;
  }
}