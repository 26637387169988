.btn {
  min-width: 220px;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.12em;
  font-weight: 700;
  text-align: center;
  border: 2px solid $blue;
  background-color: $blue;
  color: $white;
  cursor: pointer;
  display: inline-block;
  text-transform: uppercase;
  padding: 16px;
  transition: opacity 0.15s $easing;

  &.sm {
    padding: 10px 16px;
    min-width: 140px;
  }

  &.scnd {
    background-color: transparent;
    color: $blue;
  }

  &.white {
    border: 2px solid $white;
    background-color: $white;
    color: $blue;
  
    &.scnd {
      background-color: transparent;
      color: $white;
    }
  }
}

.link {
  color: $blue;
  font-size: 14px;
  line-height: 22px;
  padding-bottom: 2px;
  display: inline-block;
  position: relative;
  cursor: pointer;
  transition: 0.15s $easing;

  &::before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: $blue;
  }

  &:hover::before {
    animation-duration: 0.3s;
    animation-timing-function: ease-in-out;
    animation-fill-mode: both;
    animation-name: linkWidth;
  }
  
  &--white {
    color: $white;

    &::before {
      background-color: $white;
    }
  }
}

.click {
  cursor: pointer;
}

.btn, .click {  
  &:hover {
    opacity: 0.6;
  }
}