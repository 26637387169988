.cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: $white;
  z-index: 9;
  padding: 10px 0 11px;

  @media (max-width: $md - 1px) {
    padding: 30px 0;
  }

  .wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $md - 1px) {
      display: block;
    }

    p {
      max-width: 760px;
    }

    .btn {
      margin-left: 40px;

      @media (max-width: $md - 1px) {
        margin-left: 0;
        margin-top: 20px;
        width: 100%;
        max-width: 300px;
      }
    }
  }
}