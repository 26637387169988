.linkblock {
  margin: 200px 0;

  @media (max-width: $md - 1px) {
    margin: 100px 0;
  }
  
  &_wrap {
    color: $white;
    padding: 80px 30px;
    position: relative;

    @media (max-width: $md - 1px) {
      padding: 40px 20px 20px;
    }
  }

  &_bg {
    z-index: 0;
  }

  &_text {
    position: relative;
    z-index: 1;
    text-align: center;

    .btn {
      margin-top: 50px;
      min-width: 260px;

      @media (max-width: $md - 1px) {
        min-width: 0;
        width: 100%;
        max-width: 400px;
        margin-top: 20px;
      }
    }
  }

  // .target {
  //   .linkblock_bg, .linkblock_text {
  //     opacity: 0;
  //     transform: translateY(20px);
  //   }

  //   &.trigger {
  //     .linkblock_bg, .linkblock_text {
  //       opacity: 1;
  //       transform: translateY(0);
  //       transition: 0.7s $easing;
  //     } 
      
  //     .linkblock_text {
  //       transition-delay: 0.2s;
  //     }
  //   }
  // }
}