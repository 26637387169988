.business {
  margin: 200px 0;

  &--lmgb {
    margin-bottom: 94px;
  }

  &--lmgt {
    margin-top: 137px;
  }

  @media (max-width: $md - 1px) {
    margin: 150px 0;
  }

  .wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: $md - 1px) {
      flex-wrap: wrap;
      position: relative;
    }
  }

  &_dots {
    position: absolute;

    @media (min-width: $md) {
      bottom: 80px;
      right: 45%;
      transform: translateX(-50%);
    }

    @media (max-width: $md - 1px) {
      top: -66px;
      left: 0;

      .hoverdots {
        width: 176px;
        height: 176px;

        &_dot {
          width: 12.5%;
          padding-bottom: 12.5%;

          &:nth-child(12n), &:nth-child(12n-1), &:nth-child(12n-2), &:nth-child(12n-3), &:nth-child(96) ~ .hoverdots_dot {
            display: none;
          }
        }
      }
    }
  }

  &_text {
    max-width: 350px;

    @media (max-width: $md - 1px) {
      order: 1;
      max-width: none;
      width: 100%;
      margin-bottom: 60px;
    }

    h3 {
      margin-bottom: 70px;

      @media (max-width: $md - 1px) {
        margin-bottom: 1em;
      }
    }

    p {
      margin-top: 1em;
    }

    .link {
      margin-top: 40px;
    }

    &__inner {
      max-width: 340px;
    }
  }

  &_stats {
    @media (max-width: $md - 1px) {
      width: 100%;
      order: 2;
    }
  }

  &_stat {
    color: $blue;
    margin-bottom: 50px;
    opacity: 0;
    transform: translateY(20px);

    &.fadeIn {
      transition: 1s linear;
      opacity: 1;
      transform: translateY(0);
    }

    @media (max-width: $md - 1px) {
      margin-bottom: 10px;
    }

    &:last-child {
      margin-bottom: 20px;

      @media (max-width: $md - 1px) {
        margin-bottom: 0;
      }
    }

    h2 {
      position: relative;
      white-space: nowrap;
      min-width: 210px;

      @media (max-width: $md - 1px) {
        font-size: 58px;
        line-height: 70px;
      }

      &::after {
        content: attr(data-after);
        display: inline-block;
        font-size: 14px;
        line-height: 16px;
        font-weight: 400;
        letter-spacing: 0;
        height: 84px;
        text-align: right;
        writing-mode: vertical-rl;
        transform: translateY(10px) rotate(180deg);
        margin-left: 20px;
        white-space: normal;

        @media (max-width: $md - 1px) {
          height: 70px;
        }
      }
    }
  }

  // .target {
  //   .business {
  //     &_dots {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }

  //     &_text {
  //       h3, p, .link {
  //         opacity: 0;
  //         transform: translateY(20px);
  //       }
  //     }
  //   }

  //   &.trigger {
  //     .business {
  //       &_dots {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing 0.2s;
  //       }

  //       &_text {
  //         h3, p, .link {
  //           opacity: 1;
  //           transform: translateY(0);
  //         }

  //         h3 {
  //           transition: 0.7s $easing 0.4s;
  //         }

  //         p {
  //           transition: 0.7s $easing 0.6s;
  //         }

  //         .link {
  //           transition: 0.7s $easing 0.8s;
  //         }
  //       }
  //     }
  //   }
  // }
}