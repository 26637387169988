.offsetphoto {
  .container {
    position: relative;
  }

  img {
    width: calc(50% - 50px);
    height: auto;
    position: relative;
    z-index: 2;
    margin: 0;
    margin-left: auto;

    @media (max-width: $md - 1px) {
      margin: 0;
      width: calc(100% + 35px);
      left: 0;
    }
  }

  &_dots {
    z-index: 1;
    position: absolute;
    top: calc(100% + 22px); 
    left: 55%;

    @media (max-width: $md - 1px) {
      left: auto;
      right: 0;

      .hoverdots {
        width: 176px;
        height: 176px;
  
        &_dot {
          width: 12.5%;
          padding-bottom: 12.5%;
  
          &:nth-child(12n), &:nth-child(12n-1), &:nth-child(12n-2), &:nth-child(12n-3), &:nth-child(96) ~ .hoverdots_dot {
            display: none;
          }
        }
      }
    }
  }

  &_max {
    margin: 137px 0px 200px;

    @media (max-width: $md - 1px) {
      margin: 40px 0px 70px;
    }

    img {
      width: calc(90% - 50px);

      @media (max-width: $md - 1px) {
        margin: 0;
        width: calc(100% + 35px);
        left: 0;
      }
    }
  }

  // &.target {
  //   img {
  //     transform: translateX(20px);
  //     opacity: 0;
  //   }

  //   .offsetphoto_dots {
  //     transform: translateY(20px);
  //     opacity: 0;
  //   }

  //   &.trigger {
  //     img, .offsetphoto_dots {
  //       opacity: 1;
  //       transition: 0.7s $easing;
  //     }

  //     img {
  //       transform: translateX(0);
  //     }

  //     .offsetphoto_dots {
  //       transform: translateY(0);
  //       transition-delay: 0.2s;
  //     }
  //   }
  // }
}