.searchres {
  .wrap > p {
    span {
      opacity: 0.5;
    }
  }

  &_list {
    margin-top: 10px;
  }

  &_item {
    padding: 10px 0;
    color: $blue;

    &:hover {
      text-decoration: underline;
      opacity: 1;
    }
  }

  // .target {
  //   > p, .searchres_item {
  //     opacity: 0;
  //     transform: translateY(20px);
  //   }

  //   &.trigger {
  //     > p, .searchres_item {
  //       opacity: 1;
  //       transform: translateY(0);
  //       transition: 0.7s $easing 0.2s;
  //     }

  //     .searchres_item {
  //       @for $k from 1 to 20 {
  //         &:nth-child(#{$k}) {
  //           $delay: 0.2s + (0.2s * $k);
  //           transition-delay: $delay;
            
  //           > * {
  //             transition-delay: $delay;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}