.textblocks {
  .icon {
    height: 60px;
    width: 100%;
    margin-bottom: 1em;
    color: $blue;

    svg, img {
      height: 100%;
      max-width: 100%;
    }
  }

  > h3 {
    margin-bottom: 50px;

    @media (max-width: $md - 1px) {
      margin-bottom: 30px;
    }
  }

  &_list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -30px;

    @media (max-width: $md - 1px) {
      margin: -20px;
    }

    @media (max-width: $sm - 1px) {
      margin: -15px;
    }
  }

  &_block {
    width: calc(#{percentage(1/3)} - 60px);
    margin: 30px;

    @media (max-width: $md - 1px) {
      width: calc(50% - 40px);
      margin: 20px;
    }

    @media (max-width: $sm - 1px) {
      width: calc(100% - 30px);
      margin: 15px;
    }

    ul {
      font-size: 14px;
      line-height: 24px;
      margin: 0.5em 0;
    }

    h3 {
      margin-bottom: 10px;
    }

    .spacer {
      height: 2px;
      width: 30px;
      background-color: $blue;
      margin: 20px 0;
    }

    > .icon {
      margin-bottom: 30px;
    }
  }

  .placeholder {
    height: 138px;

    @media (max-width: $md - 1px) {
      display: none;
    }
  }

  // .target {
  //   > h3, > .icon {
  //     opacity: 0;
  //     transform: translateY(20px);
  //   }

  //   .textblocks_block {
  //     .icon, h3, .spacer, h6, ul {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }
  //   }

  //   &.trigger {
  //     > h3, > .icon {
  //       opacity: 1;
  //       transform: translateY(0);
  //       transition: 0.7s $easing;

  //       + h3 {
  //         transition: 0.7s $easing 0.2s;
  //       }
  //     }

  //     .textblocks_block {
  //       h3, .spacer, h6, .icon, ul {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing;
  //       }

  //       @for $k from 1 to 15 {
  //         &:nth-child(#{$k}) {
  //           $delay: 0.2s + (0.2s * $k);
  //           transition-delay: $delay;

  //           h3 {
  //             transition-delay: $delay;
  //           }

  //           .spacer, h6 {
  //             transition-delay: $delay + 0.2s;

  //             + h6 {
  //               transition-delay: $delay + 0.4s;
  //             }
  //           }

  //           ul {
  //             transition-delay: $delay + 0.6s;
  //           }

  //           .icon {
  //             transition-delay: $delay;

  //             ~ h3 {
  //               transition-delay: $delay + 0.2s;
  //             }

  //             ~ .spacer, ~ h6 {
  //               transition-delay: $delay + 0.4s;

  //               + h6 {
  //                 transition-delay: $delay + 0.6s;
  //               }
  //             }


  //             ~ ul {
  //               transition-delay: $delay + 0.8s;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}