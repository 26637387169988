@font-face {
  font-family: 'Larsseit';
  @include fontSrc('Larsseit');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  @include fontSrc('Larsseit-Italic');
  font-weight: 400;
  font-style: italic;
}

@font-face {
  font-family: 'Larsseit';
  @include fontSrc('Larsseit-Bold');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Larsseit';
  @include fontSrc('Larsseit-BoldItalic');
  font-weight: 700;
  font-style: italic;
}