.jobs {
  margin: 200px 0;

  @media (max-width: $md - 1px) {
    margin: 100px 0;
  }

  .wrap {
    position: relative;

    > h3 {
      position: relative;
      display: inline-block;
      z-index: 1;
      margin-bottom: 70px;

      @media (max-width: $md - 1px) {
        margin-bottom: 1em;
      }
    }
  }

  &_item, &_list {
    border-color: rgba($black, 0.1);
    border-width: 1px 0;
    border-style: solid;
  }

  &_list {
    position: relative;
    z-index: 1;
  }

  &_dots {
    position: absolute;
    left: 50%;
    bottom: calc(100% - 88px);
    transform: translateX(-50%);
    z-index: 0;

    @media (max-width: $md - 1px) {
      top: -66px;
      bottom: auto;
      transform: none;
      left: 44px;
      right: 0;

      .hoverdots {
        width: 176px;
        height: 176px;
  
        &_dot {
          width: 12.5%;
          padding-bottom: 12.5%;
  
          &:nth-child(12n), &:nth-child(12n-1), &:nth-child(12n-2), &:nth-child(12n-3), &:nth-child(96) ~ .hoverdots_dot {
            display: none;
          }
        }
      }
    }
  }

  &_item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    color: $black;
    transition: color 0.3s $easing;
    padding: 10px 0;
    min-height: 78px;

    h6.location, .svg {
      flex-shrink: 0;
      transition: opacity 0.3s $easing;
    }

    h6:not(.location) {
      width: 100%;
      padding: 0;
      transition: padding 0.3s $easing;

      @media (max-width: $md - 1px) {
        width: calc(100% - 50px);
        font-size: 14px;
        line-height: 24px;
      }
    }

    h6.location {
      margin: 0 50px;
      opacity: 0.5;
      
      @media (max-width: $md - 1px) {
        display: none;
      }
    }

    .svg {
      width: 34px;
      height: 7px;
      opacity: 0.3;

      path {
        fill: currentColor;
      }
    }

    &:hover {
      opacity: 1;
      color: $blue;

      h6:not(.location) {
        padding-left: 20px;
      }

      h6.location, .svg {
        opacity: 1;
      }
    }
  }

  // .target {
  //   > h3, .jobs_item {
  //     opacity: 0;
  //   }

  //   > h3 {
  //     transform: translateY(20px);
  //   }

  //   .jobs_item > * {
  //     transform: translateX(20px);
  //   }

  //   &.trigger {
  //     > h3, .jobs_item {
  //       opacity: 1;
  //     }
  
  //     > h3 {
  //       transform: translateY(0);
  //       transition: 0.7s $easing;
  //     } 

  //     .jobs_item {
  //       > * {
  //         transform: translateX(0);
  //       }
        
  //       @for $k from 1 to 20 {
  //         &:nth-child(#{$k}) {
  //           $delay: 0.2s + (0.2s * $k);
  //           transition: transform 0.7s $easing $delay, opacity 0.7s $easing $delay;
            
  //           > * {
  //             transition: transform 0.7s $easing $delay, opacity 0.7s $easing $delay;
  //           }
            
  //           > h6:not(.location) {
  //             transition: transform 0.7s $easing $delay, opacity 0.7s $easing $delay, padding 0.3s $easing;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}