body {
  background-color: $dwhite;
  color: $black;
  font-family: $font;
  font-weight: 400;
  min-height: 100vh;

  &.fixed {
    height: 100%;
    overflow: hidden;
  }
}

.container {
  max-width: $xl;
  width: 100%;
  padding: 0 50px;
  margin: 0 auto;
  
  @media (max-width: $md - 1px) {
    padding: 0 35px;
  }
  
  .wrap {
    padding: 0 7%;
    width: 100%;
      
    @media (max-width: $md - 1px) {
      padding: 0;
    }
  }

  &_txt {
    max-width: $txtWidth;
  }
}

main {
  overflow: hidden;

  section {
    margin: 100px 0;
    position: relative;
    z-index: 1;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.flexWrap {
  display: flex;
}

.noMob {

  @media (max-width: $md - 1px) {
    display: none !important;
  }
}

.onlyMob {

  @media (min-width: $md ) {
    display: none !important;
  }
}

.hyphens {
  hyphens: auto;
}

