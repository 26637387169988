.testi {
  margin: 200px 0;

  @media (max-width: $md - 1px) {
    margin: 100px 0;
  }

  &.withphoto {
    @media (min-width: $md) {
      margin: 300px 0 200px;
    }
  }

  .container .wrap {
    position: relative;
    padding-bottom: 60px;

    h3 {
      margin-bottom: 70px;
      position: relative;
      z-index: 1;

      @media (max-width: $md - 1px) {
        margin-bottom: 1em;
      }

      &::before {
        content: '';
        position: absolute;
        height: 1.29em;
        width: 1.29em;
        left: -0.37em;
        top: -0.87em;
        @include bgContain;
        background-image: url(../img/quotes.svg);
        opacity: 0.1;
        z-index: -1;
      }
    }
  }

  .slick {
    &-arrow {
      position: absolute;
      top: calc(100% + 30px);
      width: 34px;
      height: 30px;
      cursor: pointer;
      font-size: 0;
      padding: 0;
      margin: 0;
      border: 0;
      background: transparent;
      @include bgContain;
      opacity: 0.3;
      transition: 0.15s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }

    &-prev {
      left: 0;
      background-image: url(../img/testi-left.svg);
    }

    &-next {
      left: 64px;
      background-image: url(../img/testi-right.svg);
    }

    &-initialized {
      .slick-slide {
        img {
          transition: 0.7s $easing 0.7s;
        }

        .testi_text {
          h6 {
            transition: 0.7s $easing 0.9s;
          }

          p {
            transition: 0.7s $easing 1.1s;
          }

          p.fade {
            transition: 0.7s $easing 1.3s;
          }
        }

        &:not(.slick-active) {
          img {
            opacity: 0;
            transform: translateY(-10%);
          }

          .testi_text {
            h6, p {
              opacity: 0;
              transform: translateY(10%);
            }
          }
        }

        &.slick-active {
          img {
            opacity: 1;
            transform: translateY(0%);
          }

          .testi_text {
            h6, p {
              opacity: 1;
              transform: translateY(0%);

              &.fade {
                opacity: 0.5;
              }
            }
          }
        }
      }
    }
  }

  &_slides {
    position: relative;
    max-width: 550px;
    z-index: 1;
  }

  &_decors {
    z-index: 0;
    position: absolute;
    left: 43%;
    top: 60px;
    transform: translateX(-50px);

    @media (max-width: $md - 1px) {
      .hoverdots {
        width: 176px;
        height: 176px;

        &_dot {
          width: 12.5%;
          padding-bottom: 12.5%;

          &:nth-child(12n), &:nth-child(12n-1), &:nth-child(12n-2), &:nth-child(12n-3), &:nth-child(96) ~ .hoverdots_dot {
            display: none;
          }
        }
      }
    }
  }

  &_photo {
    z-index: 0;
    position: absolute;
    right: 0px;
    top: 0;
    transform: translateY(-50%);
    max-width: 40%;
    z-index: 0;

    @media (max-width: $md - 1px) {
      display: none;
    }

    .hoverdots {
      position: absolute;
      left: -#{percentage(1/3)};
      top: -17%;
      max-width: 50%;
    }

    img {
      position: relative;
      z-index: 1;
      width: 100%;
      height: auto;
    }
  }

  &_slide {
    img, h6, p {
      transition: 0.3s $easing;
      transform: translateY(0);
    }

    img {
      height: 40px;
      width: auto;
      opacity: 1;
    }

    h6 {
      margin-top: 2em;
      margin-bottom: 0.5em;
      opacity: 1;
    }

    p {
      opacity: 1;

      &.fade {
        opacity: 0.5;
      }
    }
  }

  // .target {
  //   > h3 {
  //     opacity: 0;
  //     transform: translateY(20px);
  //   }

  //   .testi {
  //     &_slides {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }

  //     &_decors {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }

  //     &_photo {
  //       opacity: 0;
  //       transform: translateY(-40%);
  //     }
  //   }

  //   &.trigger {
  //     > h3 {
  //       opacity: 1;
  //       transform: translateY(0);
  //       transition: 0.7s $easing;
  //     }

  //     .testi {
  //       &_slides {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing 0.2s;
  //       }

  //       &_decors {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing 0.4s;
  //       }

  //       &_photo {
  //         opacity: 1;
  //         transform: translateY(-50%);
  //         transition: 0.7s $easing 0.6s;
  //       }
  //     }
  //   }
  // }
}