.history {
  margin: 300px 0;

  &--small-margin {
    margin: 100px 0;
  }

  @media (max-width: $md - 1px) {
    margin: 150px 0;
  }

  .wrap {
    &.bouncing {
      pointer-events: none;

      &.bounced {
        pointer-events: auto;
      }
    }
    position: relative;
  }

  &_text {
    max-width: 550px;

    h3 {
      margin-bottom: 10px;
    }
  }

  .time-nav {
    margin: 50px 0 30px;
    display: flex;
    position: relative;

    @media (max-width: $md - 1px) {
      top: 0;
      justify-content: flex-start;
    }

    .time-nav__prev,
    .time-nav__next {
      width: 34px;
      height: 30px;
      opacity: 0.5;
      cursor: pointer;
      transition: 0.3s ease-in-out;
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;

      &.disabled {
        pointer-events: none !important;
      }

      &:hover {
        opacity: 1;
      }
    }
    .time-nav__prev {
      background-image: url(../img/testi-left.svg);
      margin-right: 20px;
    }
    .time-nav__next {
      background-image: url(../img/testi-right.svg);
    }
    @media (max-width: $md) {
      margin-bottom: 40px;
    }
  }

  &_scroller {
    display: none;
    text-align: center;
    margin-bottom: 40px;

    @media (max-width: $md - 1px) {
      display: block;
    }

    img {
      display: inline-block;
      width: 30px;
      height: 50px;
      animation: scrollLeft 1.5s linear 0s infinite;
    }
  }

  &_shads {
    position: relative;
    overflow-y: hidden;
    // padding-left: 10px;

    &::before,
    &::after {
      content: "";
      width: 50px;
      height: 100%;
      top: 0;
      pointer-events: none;
      position: absolute;
      z-index: 3;
    }

    &::before {
      display: none;
      right: 0;
      background: $wgrad;
    }

    &::after {
      left: -50px;
      background: $wgradin;
    }
  }

  &_list {
    // width: calc(100% + 100px);

    position: absolute;

    margin-left: -50px;
    // position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 9px 9px 9px 50px;
    opacity: 1;
    pointer-events: auto;
    transition: 0.7s $easing 0.4s;

    .ps {
      &__thumb {
        &-x,
        &-y {
          background-color: $blue !important;
        }

        &-x {
          height: 0px !important;
        }
      }

      &__rail {
        &-x,
        &-y {
          background-color: transparent !important;
        }

        &-x {
          height: 0px !important;
        }
      }
    }
  }

  &_item {
    width: 130px;
    position: relative;
    flex-shrink: 0;
    display: flex;
    min-height: 190px;

    &:first-child {
      margin-left: 10px;
      .point {
        left: -9px;
        bottom: -9px;
        height: 18px;
        width: 18px;
      }
    }

    .point {
      position: absolute;
      left: -6px;
      bottom: -6px;
      height: 12px;
      width: 12px;
      border-radius: 50%;
      background-color: $blue;
      transform-origin: center center;
      transform: scale(1);
    }

    .inner {
      display: flex;
      left: 0;
      width: 200%;
      flex-shrink: 0;
      position: relative;

      .content {
        max-width: 170px;

        h6 {
          position: relative;
          transform: translateY(0);
          opacity: 1;
        }

        p {
          transform: translateY(0);
          opacity: 1;
        }
      }

      &::before {
        content: "";
        position: absolute;
        left: 0;
        height: calc(100% - 6px);
        width: 1px;
        opacity: 0.2;
        transform: translateX(-50%);
      }

      &::after {
        content: "";
        position: absolute;
        left: 0;
        width: 6px;
        height: 6px;
        background-color: rgba($blue, 0.2);
        border-radius: 50%;
        transform-origin: center center;
        transform: scale(1) translateX(-50%);
      }
    }

    &:not(:last-child) {
      padding-bottom: 1px;

      &::before {
        content: "";
        position: absolute;
        left: 0;
        bottom: -1px;
        background-color: $blue;
        height: 2px;
        width: 100%;
        opacity: 1;
      }
    }

    &:nth-child(odd) {
      .inner {
        padding: 0 20px 20px;
        align-items: flex-start;

        &::before {
          bottom: 0;
          background: $blgrad;
        }

        &::after {
          top: 0;
        }
      }
    }

    &:nth-child(even) {
      .inner {
        padding: 20px 20px 0;
        transform: translateY(100%);
        align-items: flex-end;

        &::before {
          top: 0;
          background: $blgradin;
        }

        &::after {
          bottom: 0;
        }
      }
    }
  }

  // .target {
  //   .history {
  //     &_text {
  //       h3, p {
  //         transform: translateY(20px);
  //         opacity: 0;
  //       }
  //     }

  //     &_item {
  //       &::before {
  //         width: 0;
  //       }

  //       .point {
  //         transform: scale(0);
  //       }

  //       .inner {
  //         &::before {
  //           height: 0;
  //         }

  //         &::after {
  //           transform: scale(0) translateX(-50%);
  //         }

  //         .content {
  //           h6, p {
  //             transform: translateY(20px);
  //             opacity: 0;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   &.trigger {
  //     .history {
  //       &_text {
  //         h3, p {
  //           transform: translateY(0);
  //           opacity: 1;
  //         }

  //         h3 {
  //           transition: 0.7s $easing;
  //         }

  //         p {
  //           transition: 0.7s $easing 0.2s;
  //         }
  //       }

  //       &_item {
  //         &::before {
  //           width: 100%;
  //           transition: 0.7s $easing 0.6s;
  //         }

  //         .point {
  //           transform: scale(1);
  //           transition: 0.7s $easing 0.4s;
  //         }

  //         .inner {
  //           &::before {
  //             height: calc(100% - 6px);
  //             transition: 0.7s $easing 0.8s;
  //           }

  //           &::after {
  //             transform: scale(1) translateX(-50%);
  //             transition: 0.7s $easing 1s;
  //           }

  //           .content {
  //             h6, p {
  //               transform: translateY(0);
  //               opacity: 1;
  //             }

  //             h6 {
  //               transition: 0.7s $easing 1.2s;
  //             }

  //             p {
  //               transition: 0.7s $easing 1.4s;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
