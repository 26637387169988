h1, h2, p.h1 {
  font-size: 78px;
  line-height: 84px;
  letter-spacing: 0.02em;
  font-weight: 700;

  @media (max-width: $md - 1px) {
    font-size: 48px;
    line-height: 62px;
  }
}

h3 {
  font-size: 54px;
  line-height: 62px;
  letter-spacing: 0.02em;
  font-weight: 700;

  @media (max-width: $md - 1px) {
    font-size: 42px;
    line-height: 50px;
    letter-spacing: 0;
    word-break: break-word;
  }
}

h4 {
  font-size: 42px;
  line-height: 48px;
  letter-spacing: 0.02em;
  font-weight: 700;

  @media (max-width: $md - 1px) {
    font-size: 32px;
    line-height: 36px;
  }
}

h5 {
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.01em;
  font-weight: 700;
}

h6, .h6 {
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.01em;
  font-weight: 400;
}

p, li {
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.01em;
  font-weight: 400;

  &.prefix {
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    &::before {
      content: attr(prefix);
      opacity: 0.5;
      margin-right: 0.5em;
    }
  }

  a {
    display: inline;
  }
}

ul:not([class]) {
  li {
    padding-left: 20px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      top: 12px;
      left: 0;
      background-color: $blue;
      border-radius: 50%;
    }

    p {
      margin-top: 0;
    }
  }
}

strong, b {
  font-weight: 700;
}

em, i {
  font-style: italic;
}