.pageheading {
  margin: 150px 0;
  color: $blue;

  @media (max-width: $md - 1px) {
    margin: 100px 0;
  }

  &:first-child {
    padding: 150px 0 0;
    margin: 0 0 150px;

    @media (max-width: $md - 1px) {
      padding: 150px 0 0;
      margin: 0 0 100px;
    }
  }

  &_text {
    max-width: 800px;
    position: relative;


    h2, h1 {
      margin-bottom: 1em;
    }
  }

  &_dots {
    position: absolute;
    top: 0;
    left: 41%;

    @media (max-width: $md - 1px) {
      top: -66px;

      .hoverdots {
        width: 176px;
        height: 176px;
  
        &_dot {
          width: 12.5%;
          padding-bottom: 12.5%;
  
          &:nth-child(12n), &:nth-child(12n-1), &:nth-child(12n-2), &:nth-child(12n-3), &:nth-child(96) ~ .hoverdots_dot {
            display: none;
          }
        }
      }
    }
  }

  &_back {
    color: $blue;
    display: inline-block;
    font-size: 12px;
    line-height: 20px;
    letter-spacing: 0.1em;
    font-weight: 700;
    text-transform: uppercase;
    padding-left: 50px;
    position: relative;

    .svg {
      position: absolute;
      left: 0;
      top: 6.5px;
      width: 34px;
      height: 7px;
      transition: 0.3s $easing;

      path {
        fill: currentColor;
      }
    }

    &:hover {
      opacity: 1;

      .svg {
        left: -7px;
      }
    }
  }

  // .target {
  //   .pageheading {
  //     &_text, &_dots, &_back {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }
  //   }

  //   &.trigger {
  //     .pageheading {
  //       &_text, &_dots, &_back {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing;
  //       }

  //       &_dots {
  //         transition-delay: 0.2s;
  //       }

  //       &_back {
  //         transition-delay: 0.4s;
  //       }
  //     }
  //   }
  // }
}