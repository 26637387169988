.footer {
  margin-top: 100px;
  padding: 80px 0;
  overflow: hidden;

  @media (max-width: $md - 1px) {
    padding: 0 0 100px;
    margin-top: 50px;
  }

  .wrap {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    @media (max-width: $md - 1px) {
      display: block;
      position: relative;
    }
  }

  &_cols {
    width: 80%;

    @media (max-width: $md - 1px) {
      width: 100%;
    }

    &-inner {
      width: calc(100% + 80px);
      margin: 0 -40px;
      display: flex;

      @media (max-width: $md - 1px) {
        width: calc(100% + 30px);
        margin: 0 -15px;
        flex-wrap: wrap;
        align-items: flex-start;
      }
    }
  }

  &_col {
    width: calc(25% - 80px);
    margin: 0 40px;

    @media (max-width: $md - 1px) {
      margin: 15px;
      width: calc(50% - 30px);
      order: 2;

      &:not(:last-child) {
        display: none;
      }
    }

    p.heading {
      font-weight: 700;
      margin-bottom: 0.5em;
    }

    p:not(.heading) {
      font-size: 12px;
      line-height: 28px;

      a {
        display: inline;

        &:hover {
          opacity: 0.5;
        }
      }
    }

    ul {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      flex-wrap: wrap;

      li {
        width: 100%;
        font-size: 12px;
        line-height: 28px;

        a {
          opacity: 0.5;
          display: inline-block;

          &:hover {
            opacity: 1;
          }
        }
      }
    }
    
    &-two {
      width: calc(50% - 80px);

      @media (max-width: $md - 1px) {
        width: calc(100% - 30px);
        order: 1;
      }

      ul li {
        width: calc(50% - 30px);

        @media (max-width: $md - 1px) {
          width: calc(50% - 15px);
        }
      }
    }
  }

  &_soc {
    max-width: 20%;
    position: relative;
    display: flex;
    justify-content: flex-end;

    @media (max-width: $md - 1px) {
      width: 50%;
      margin-left: calc(50% + 20px);
      max-width: none;
      position: static;
      justify-content: flex-start;
    }

    a {
      position: relative;
      z-index: 3;
      
      &:not(:first-child) {
        margin-left: 20px;
      }

      .svg {
        width: 22px;
        height: 22px;
        
        path {
          fill: $blue;
        }
      }
    }
  }

  &_scrollTop {
    position: absolute;
    display: none;
    cursor: pointer;
    width: 54px;
    height: 54px;
    top: calc(100% + 20px);
    right: 0;
    background-color: $blue;
    z-index: 2;

    .svg {
      width: 34px;
      height: 7px;
      transform: rotate(90deg);

      path {
        fill: $white;
      }
    }

    @media (max-width: $md - 1px) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &_dots {
    position: absolute;
    top: -16px;
    right: 100px;
    z-index: 1;

    @media (max-width: $md - 1px) {
      top: calc(100% + 20px);
      left: 0px;
      right: auto;
    }
  }

  // .target {
  //   .footer {
  //     &_col {
  //       > ul > li, p {
  //         opacity: 0;
  //         transform: translateY(20px);
  //       }
  //     }

  //     &_soc {
  //       > * {
  //         opacity: 0;
  //         transform: translateY(20px);
  //       }
  //     }
  //   }

  //   &.trigger {
  //     .footer {
  //       &_col {
  //         > ul > li, > p {
  //           opacity: 1;
  //           transform: translateY(0);
  //           transition: 0.7s $easing;
  //         }
  
  //         @for $i from 1 to 5 {
  //           &:nth-child(#{$i}) {
  //             $delay: $i * 0.2s;
  
  //             > * {
  //               @for $j from 1 to 10 {
  //                 &:nth-child(#{$j}) {
  //                   $delayy: $delay + (0.2s * $j);
  //                   transition-delay: $delayy;
  
  //                   > li {
  //                     @for $k from 1 to 20 {
  //                       &:nth-child(#{$k}) {
  //                         $delayyy: $delayy + (0.2s * $k);
  //                         transition-delay: $delayyy;
  //                       }
  //                     }
  //                   }
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }

  //       &_soc {
  //         > * {
  //           opacity: 1;
  //           transform: translateY(0);
  //           transition: 0.7s $easing;
            
  //           @for $k from 1 to 4 {
  //             &:nth-child(#{$k}) {
  //               $delay: 0.2s * $k;
  //               transition-delay: $delay;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}