.newsletter_block {
    position: relative;
    padding: 81px 41px;

    @media (max-width: $md - 1px) {
        padding: 23px 20px 20px;
    }

    &__text {
        transition: .3s cubic-bezier();

        &.disabled {
            opacity: 0;
            visibility: hidden;
            pointer-events: none;
        }
    }

    &__title {
        color: $white;
        font-size: 54px;
        text-align: center;
        font-weight: bold;
        line-height: 63px;
        letter-spacing: .02em;
        margin-bottom: 10px;

        @media (max-width: $md - 1px) {
            font-size: 35px;
            line-height: 43px;
        }
    }

    &__subtitle {
        color: $white;
        font-size: 14px;
        text-align: center;
        line-height: 24px;
        letter-spacing: .01em;
        max-width: 80%;
        margin: 0 auto;
        margin-bottom: 35px;

        @media (max-width: $md - 1px) {
            max-width: 90%;
        }
    }

    &__form {
        max-width: 66%;
        margin: 0 auto;
        padding-right: 15%;

        @media (max-width: $md - 1px) {
            max-width: 100%;
            padding-right: 0;
        }

        .form_input {
            color: $white;

            input {
                color: inherit;
            }

            label {
                color: inherit;

                @media (max-width: $md - 1px) {
                    line-height: 24px;
                }
            }

            a {
                color: inherit;
            }

            &-checkbox {


                label {

                    &::before {
                        border-color: $white;
                    }

                    &::after {
                        background-image: url(../img/yes-white.svg);
                    }
                }
            }
        }
    }

    &__resp {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: .3s cubic-bezier();

        &_icon {
            text-align: center;
        }

        &_title {
            color: $white;
            font-size: 42px;
            line-height: 62px;
            letter-spacing: .02em;
            font-weight: bold;
            text-align: center;
        }

        &_subtitle {
            color: $white;
            font-size: 14px;
            line-height: 24px;
            text-align: center;
        }

        &.disabled {
            opacity: 0;
            visibility: hidden;
        }
    }
}