$xl: 1366px;
$lg: 1200px;
$md: 992px;
$sm: 600px;
$basepx: 14px;
$txtWidth: 800px;

$font: 'Larsseit', sans-serif;

$black:  #000;
$white:  #FFF;
$dwhite: #F6F7FA;
$lblue:  #D4D3F2;
$blue:   #2522BF;
$red:    #D14841;

$bgrad: linear-gradient(0deg, rgba($blue, 0.7), rgba($blue, 1));
$bgradin: linear-gradient(180deg, rgba($blue, 0.7), rgba($blue, 1));
$blgrad: linear-gradient(0deg, rgba($blue, 0.3), rgba($blue, 1));
$blgradin: linear-gradient(180deg, rgba($blue, 0.3), rgba($blue, 1));
$bfgrad: linear-gradient(180deg, rgba($blue, 0), rgba($blue, 1));
$bfhgrad: linear-gradient(90deg, rgba($blue, 0), rgba($blue, 1));
$bfhrevgrad: linear-gradient(270deg, rgba($blue, 0), rgba($blue, 1));
$wgrad: linear-gradient(90deg, rgba($dwhite, 0), rgba($dwhite, 1));
$wgradin: linear-gradient(270deg, rgba($dwhite, 0), rgba($dwhite, 1));
$easing: cubic-bezier(.20,.60,.30,1);
$bfdgrad: linear-gradient(180deg, rgba($black, 0.1), rgba($black, 0));