.faq {
  margin: 150px 0;

  .wrap {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    > h3 {
      width: calc(50% - 50px);

      @media (max-width: $md - 1px) {
        width: 100%;
      }
    }
  }

  &_list {
    width: 50%;

    @media (max-width: $md - 1px) {
      width: 100%;
      margin-top: 35px;
    }
  }

  &_item, &_list {
    border-color: rgba($black, 0.1);
    border-width: 1px 0;
    border-style: solid;
  }

  &_toggler {
    position: relative;
    padding-top: 30px;
    cursor: pointer;

    &::before, &::after {
      content: "";
      position: absolute;
      right: 20px;
      width: 24px;
      top: calc(50% - 1px);
      height: 2px;
      background-color: $blue;
      transition: transform 0.3s $easing;
      transform-origin: center center;

      @media (max-width: $md - 1px) {
        right: 0;
      }
    }

    &::after {
      transform: rotate(90deg);
    }

    @media (max-width: $md - 1px) {
      padding-top: 10px;
      min-height: 78px;
      display: flex;
      align-items: center;

      h6 {
        width: 100%;
        font-size: 14px;
        line-height: 24px;
      }
    }
  }

  &_text {
    overflow: hidden;
    opacity: 0;
    transition: height 0.3s $easing, opacity 0.3s $easing;
  }

  &_item {
    &.active {
      .faq_toggler {
        &::before, &::after {
          transform: rotate(180deg);
        }
      }

      .faq_text {
        opacity: 1;
      }
    }

    &:not(.active) {
      .faq_text {
        height: 0 !important;
      }
    }
  }

  &_toggler, &_text-wrap {
    padding-bottom: 30px;
    padding-right: 90px;
    padding-left: 20px;

    @media (max-width: $md - 1px) {
      padding-bottom: 10px;
      padding-right: 35px;
      padding-left: 0;
    }
  }

  // .target {
  //   > h3, .faq_item, .faq_item .faq_toggler {
  //     opacity: 0;
  //   }

  //   > h3 {
  //     transform: translateY(-20px);
  //   }

  //   .faq_item .faq_toggler {
  //     transform: translateX(20px);
  //   }

  //   &.trigger {
  //     > h3, .faq_item, .faq_item .faq_toggler {
  //       opacity: 1;
  //       transition: 0.7s $easing;
  //     }

  //     > h3 {
  //       transform: translateY(0);
  //     }


  //     .faq_item {
  //       .faq_toggler {
  //         transform: translateX(0);
  //       }

  //       @for $k from 1 to 15 {
  //         &:nth-child(#{$k}) {
  //           $delay: 0.2s + (0.2s * $k);
  //           transition-delay: $delay;

  //           .faq_toggler {
  //             transition-delay: $delay;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}