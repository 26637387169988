.iconblocks {
  &_list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: -30px;

    @media (max-width: $md - 1px) {
      margin: -20px;
    }

    @media (max-width: $sm - 1px) {
      margin: -15px;
    }
  }

  ul {
    font-size: 14px;
    line-height: 24px;
    margin: 0.5em 0;
  }

  li {
    padding-left: 14px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      width: 4px;
      height: 4px;
      top: 10px;
      left: 0;
      background-color: $blue;
      border-radius: 50%;
    }
  }

  h3 {
    margin-bottom: 70px;

    @media (max-width: $md - 1px) {
      margin-bottom: 1em;
    }
  }

  &_block {
    width: calc(#{percentage(1/3)} - 60px);
    margin: 30px;

    @media (max-width: $md - 1px) {
      width: calc(50% - 40px);
      margin: 20px;
    }

    @media (max-width: $sm - 1px) {
      width: calc(100% - 30px);
      margin: 15px;
    }

    .icon {
      height: 60px;
      width: 100%;
      margin-bottom: 30px;
      color: $blue;

      svg, img {
        height: 100%;
        max-width: 100%;
      }
    }

    h5 {
      margin-bottom: 10px;
    }
  }

  & ~ & {
    margin-top: 243px;
  }


  &-multiple {

    .text-lg {
      margin-bottom: 17px;
    }

    .textphoto_text {

      p + p {
        margin-bottom: 65px;
      }
    }

    .textblocks_list {
      margin-bottom: 65px;
    }
  }


  // .target {
  //   > h3 {
  //     opacity: 0;
  //     transform: translateY(20px);
  //   }

  //   .iconblocks_block {
  //     .icon, h5, p, ul {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }
  //   }

  //   &.trigger {
  //     > h3 {
  //       opacity: 1;
  //       transform: translateY(0);
  //       transition: 0.7s $easing;
  //     }

  //     .iconblocks_block {
  //       .icon, h5, p, ul {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing;
  //       }

  //       @for $k from 1 to 15 {
  //         &:nth-child(#{$k}) {
  //           $delay: 0.2s + (0.2s * $k);
  //           transition-delay: $delay;

  //           .icon {
  //             transition-delay: $delay;
  //           }

  //           h5 {
  //             transition-delay: $delay + 0.2s;
  //           }

  //           p {
  //             transition-delay: $delay + 0.4s;
  //           }

  //           ul {
  //             transition-delay: $delay + 0.6s;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}