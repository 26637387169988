.mega {
  padding: 50px 0;

  @media (max-width: $md - 1px) {
    padding: 70px 0;
  }

  .container {
    @media (max-width: $md - 1px) {
      margin: 0;
      width: 100%;
      height: 100%;
      background-image: $bfdgrad;
      padding-bottom: 50px;
      overflow: auto;
    }

    .wrap {
      display: flex;

      @media (min-width: $md) {
        padding: 0;
      }

      @media (max-width: $md - 1px) {
        display: block;
      }

      .back {
        color: currentColor;
        display: inline-block;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1em;
        font-weight: 700;
        text-transform: uppercase;
        padding-left: 50px;
        position: fixed;
        top: 25px;
        left: 35px;
        cursor: pointer;

        @media (min-width: $md) {
          display: none;
        }

        .svg {
          position: absolute;
          left: 0;
          top: 6.5px;
          width: 34px;
          height: 7px;
          transition: 0.3s $easing;

          path {
            fill: currentColor;
          }
        }

        &:hover {
          opacity: 1;

          .svg {
            left: -7px;
          }
        }
      }
    }
  }

  &_switch {
    display: none;
    position: relative;
    margin: 20px 0;

    @media (max-width: $md - 1px) {
      display: block;
    }

    &-toggle,
    &-list {
      background-color: #1e1b9d;
    }

    &-toggle,
    &-list ul li {
      font-size: 14px;
      line-height: 24px;
      padding: 13px 20px;
      cursor: pointer;
    }

    &-toggle {
      position: relative;
      padding-right: 40px;
      border: 1px solid currentColor;

      &::before {
        content: "";
        position: absolute;
        top: calc(50% - 3px);
        right: 20px;
        width: 6px;
        height: 6px;
        border-top: 6px solid currentColor;
        border-left: 3px solid transparent;
        border-right: 3px solid transparent;
      }
    }

    &-list {
      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      max-height: 150px;
      overflow: auto;
      display: none;
      z-index: 5;

      ul li {
        opacity: 0.5;
      }
    }

    &.open {
      .mega_switch-list {
        display: block;
      }
    }
  }

  &_icon {
    width: 22px;
    height: 22px;
    flex-shrink: 0;

    svg {
      width: 100%;
      height: 100%;

      path {
        fill: currentColor;
      }
    }
  }

  &_placeholder {
    width: calc(75% + 1px);
    border-left: 2px solid rgba($black, 0.08);
    transition: 0.3s $easing 0.15s;

    @media (max-width: $md - 1px) {
      display: none;
    }
  }

  &_togglers {
    width: calc(25% - 1px);
    position: relative;
    margin: -18px 0;

    @media (min-width: $md) {
      width: calc(20% - 1px);
    }

    @media (max-width: $md - 1px) {
      margin: 0;
      width: 100%;
    }
  }

  &_toggler {
    @media (min-width: $md) {
      padding-right: 50px;
      transition: 0.3s $easing;
    }

    > .mega_toggler-inner {
      display: flex;
      align-items: center;
      padding: 18px 0;
      cursor: pointer;

      @media (max-width: $md - 1px) {
        display: none;
      }

      > .mega_icon {
        margin-right: 8px;
      }

      h6,
      .h6 {
        position: relative;

        &.has-ads-counter {
          padding-right: 25px;
        }

        &::before {
          content: "";
          position: absolute;
          top: 100%;
          left: 0;
          width: 0;
          height: 2px;
          background-color: currentColor;
          transition: 0.3s $easing;
        }
      }
    }

    @media (min-width: $md) {
      &.bruksnys {
        padding-top: 18px;

        > .mega_toggler-inner {
          position: relative;

          &::before {
            content: "";
            position: absolute;
            left: 0;
            width: 100%;
            height: 2px;
            background-color: rgba($black, 0.1);
            transition: 0.3s $easing;
            top: -10px;
          }
        }
      }

      &:hover,
      &.active {
        > .mega_toggler-inner {
          color: $blue;

          h6::before,
          .h6::before {
            width: 100%;
          }

          h6,
          .h6 {
            &.has-ads-counter {
              &::before {
                width: calc(100% - 25px);
              }
            }
          }
        }

        .mega_submenu {
          pointer-events: auto;
          opacity: 1;
          transform: translateX(0);
          transition: 0.3s $easing 0.3s;
        }
      }
    }

    @media (max-width: $md - 1px) {
      &:not(.active) {
        display: none;
      }
    }
  }

  .listtitle {
    padding: 15px 18px 9px;
    font-weight: 700;
    width: 100%;

    @media (max-width: $md - 1px) {
      display: none;
    }
  }

  &_submenu {
    @media (min-width: $md) {
      position: absolute;
      left: 100%;
      width: 400%;
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0 25px;
      top: 0;
      pointer-events: none;
      opacity: 0;
      transform: translateX(20px);
      transition: 0.3s $easing;
    }

    &.overflow {
      &::after {
        @media (min-width: $md) {
          content: "";
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          height: 50px;
          background: rgb(255, 255, 255);
          background: linear-gradient(
            0deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
          );
        }
      }

      .mega_submenu__inner {
        overflow: auto;
        padding-bottom: 30px;
      }
    }

    &__inner {
      max-height: calc(100vh - 150px);
      width: 100%;
      position: relative;
      display: flex;
      flex-wrap: wrap;
    }
  }

  &_subitem {
    width: percentage(1/3);

    @media (max-width: $md - 1px) {
      width: 100%;
    }

    a {
      // old 18px 15px
      padding: 10px 15px;

      @media (max-width: $md - 1px) {
        padding: 18px 0;
      }

      h6,
      .h6 {
        // margin-top: 10px;
        line-height: 24px;
        @media (max-width: $md - 1px) {
          margin-top: 10px;
          line-height: 28px;
        }

        sup {
          vertical-align: super;
          font-weight: 700;
          font-size: 10px;
          opacity: 0.3;
          text-transform: uppercase;
        }
      }

      p:not(.h6) {
        opacity: 0.4;
      }
    }

    @media (min-width: $md) {
      &:hover,
      &-highlight {
        a {
          color: $blue;
          opacity: 1;

          h6::before,
          .h6::before {
            width: 100%;
          }
        }
      }
    }
  }
}
