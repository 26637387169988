.page404 {
  display: flex;
  flex-direction: row;
  position: relative;
  background-color: $white;

  &_wrap {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  // .target {
  //   .form_message {
  //     svg, .icon, h4, p, a {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }
  //   }

  //   &.trigger {
  //     svg, .icon, h4, p, a {
  //       opacity: 1;
  //       transform: translateY(0);
  //       transition: 0.7s $easing;
        
  //       @for $k from 1 to 5 {
  //         &:nth-child(#{$k}) {
  //           $delay: 0.2s * $k;
  //           transition-delay: $delay;
  //         }
  //       }
  //     }
  //   }
  // }
}