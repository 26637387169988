.news {
  margin: 200px 0;

  @media (max-width: $md - 1px) {
    margin: 100px 0;
  }
  
  &_list {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin: 0 -4%;

    @media (max-width: $md - 1px) {
      margin: 0 -5px;
    }
  }

  &_article {
    margin: 25px 4%;
    width: 19%;

    @media (max-width: $md - 1px) {
      margin: 20px 5px;
      width: calc(50% - 10px);
    }

    &__type {
      color: $blue;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 12px;
      font-size: 18px;
      line-height: 24px;
      letter-spacing: 0.024em;
    }

    &-featured {
      width: 38%;

      @media (max-width: $md - 1px) {
        width: calc(100% - 10px);
      }

      p {
        font-size: 18px;
        line-height: 28px;
        min-height: 56px;
      }
    }

    .image_wrap {
      width: 100%;
      padding-bottom: 100%;
      position: relative;
      margin-bottom: 16px;
      overflow: hidden;

      .image {
        position: absolute;
        z-index: 0;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform-origin: center center;
        transform: scale(1);
        transition: transform 0.3s $easing;
        @include bgCover;
      }

      .date {
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $blue;
        color: $white;
        font-size: 14px;
        line-height: 24px;
        padding: 9px 18px;
        letter-spacing: 0.02em;
        font-weight: 700;
        z-index: 1;
      }
    }

    p {
      min-height: 48px;
    }

    .link {
      margin-top: 5px;

      &:hover {
        &::before {
          animation: unset;
        }
      }
    }

    &:hover {
      .image_wrap .image {
        transform: scale(1.1);
      }

      .link {
        &::before {
          animation-duration: 0.3s !important;
          animation-timing-function: ease-in-out !important;
          animation-fill-mode: both !important;
          animation-name: linkWidth !important;
        }
      }
    }
  }

  // .target {
  //   > h3 {
  //     opacity: 0;
  //     transform: translateY(20px);
  //   }

  //   .news_article {
  //     .image_wrap, p, .link {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }
  //   }

  //   &.trigger {
  //     > h3 {
  //       opacity: 1;
  //       transform: translateY(0);
  //       transition: 0.7s $easing;
  //     }
  
  //     .news_article {
  //       .image_wrap, p, .link {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing;
  //       }
  //       @for $i from 1 to 5 {
  //         &:nth-child(#{$i}) {
  //           $delay: $i * 0.2s + 0.2s;

  //           .image_wrap {
  //             transition-delay: $delay;
  //           }

  //           p {
  //             transition-delay: $delay + 0.2s;
  //           }

  //           .link {
  //             transition-delay: $delay + 0.4s;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}