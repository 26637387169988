.contact {
  margin: 150px 0;

  @media (max-width: $md - 1px) {
    margin: 100px 0;
  }

  .wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: $md - 1px) {
      display: block;
    }
  }

  &_text {
    width: calc(35% - 20px);

    @media (max-width: $md - 1px) {
      width: 100%;
    }
  }

  &_switchable {
    margin: 50px 0;

    .tabs {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      margin: 9px -9px;
      color: $blue;

      &_tab {
        margin: 0 9px;

        input {
          display: none;

          &:checked + label::before {
            width: 100%;
            opacity: 1;
          }
        }

        label {
          color: currentColor;
          font-size: 14px;
          line-height: 30px;
          position: relative;
          cursor: pointer;
          user-select: none;

          &::before {
            content: '';
            position: absolute;
            width: 0;
            height: 2px;
            left: 50%;
            bottom: 0;
            background-color: currentColor;
            opacity: 0;
            transform: translateX(-50%);
            transition: 0.3s $easing;
          }
        }
      }
    }

    .content {
      display: none;

      &.active {
        display: block;
      }

      p:not(.prefix) {
        margin: 1em 0;

        &:first-child {
          margin-top: 0;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &_map {
    width: 65%;

    @media (max-width: $md - 1px) {
      width: 100%;
      margin-top: 100px;
    }

    &-wrap {
      position: relative;
      width: 100%;
      padding-bottom: 86%;

      @media (max-width: $md - 1px) {
        width: calc(100% + 70px);
        left: -35px;
        min-height: 375px;
      }

      #map {
        position: absolute;
        left: 0;
        top: -100px;
        width: calc(100% + 50px);
        height: 100%;
        background-color: $dwhite;

        @media (max-width: $md - 1px) {
          top: 0;
          width: 100%;
        }
      }
    }
  }

  // .target {
  //   .contact {
  //     &_map, &_text > * {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }
  //   }

  //   &.trigger {
  //     .contact {
  //       &_map, &_text > * {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing 0.2s;
  //       }

  //       &_text > * {
  //         @for $k from 1 to 10 {
  //           &:nth-child(#{$k}) {
  //             $delay: 0.2s + (0.2s * $k);
  //             transition-delay: $delay;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}