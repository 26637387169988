.tech {
  margin: 200px 0;

  &_text {
    max-width: 550px;

    h3 {
      margin-bottom: 10px;
    }
  }

  &_icons {
    margin: 30px -35px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: $md - 1px) {
      margin: 30px -15px;
    }
  }

  &_icon {
    width: 120px;
    margin: 15px 35px;
    display: flex;
    align-items: center;
    transition: opacity 0.3s $easing;

    @media (max-width: $md - 1px) {
      width: calc(50% - 30px);
      margin: 15px;
      max-width: 120px;
    }

    svg {
      width: 100%;
      height: auto;

      path {
        fill: #AFAFAF;
        transition: fill 0.3s $easing;
      }
    }

    &:hover {
      svg path {
        fill: $blue;
      }
    }
  }

  // .target {
  //   .tech {
  //     &_text {
  //       h3, h6 {
  //         opacity: 0;
  //         transform: translateY(20px);
  //       }
  //     }

  //     &_icon {
  //       opacity: 0;
  //       transform: translateY(20px);
  //       pointer-events: none;
  //     }
  //   }

  //   &.trigger {
  //     .tech {
  //       &_text {
  //         h3, h6 {
  //           opacity: 1;
  //           transform: translateY(0);
  //         }

  //         h3 {
  //           transition: 0.7s $easing;
  //         }

  //         h6 {
  //           transition: 0.7s $easing 0.2s;
  //         }
  //       }

  //       &_icon {
  //         opacity: 1;
  //         transform: translateY(0);
  //         pointer-events: auto;
  //         transition: 0.7s $easing;

  //         @for $i from 1 to 20 {
  //           &:nth-child(#{$i}) {
  //             $delay: $i * 0.2s + 0.2s;
  //             transition-delay: $delay;
  //           }
  //         }

  //         &:hover {
  //           svg path {
  //             fill: $blue;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}