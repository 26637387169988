.blogPost {

    &__date {
        color: $blue;
        margin-bottom: 31px;
        letter-spacing: .024em;
    }

    &__subtitle {
        font-size: 18px;
        letter-spacing: .01em;
        line-height: 28px;
        font-weight: normal;
        margin-bottom: 50px;
    }

    p, ul  {
        margin-bottom: 30px;
    }

    img {
        margin: 70px 0px;
        max-width: 100%;
    }

    h1, h2, h3, h4, h5, h6 {
        margin-bottom: 1em;
    }

    &__linkblock {
        margin: 160px 0px 70px;
    }
}

.blogPost_details {

    &__link {
        display: inline-block;
        line-height: 24px;
        letter-spacing: .01em;
        color: $blue;
        margin-bottom: 15px;
        margin-top: 30px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $blue;
            bottom: -10px;
            transform-origin: center center;
            transform: scaleX(0) translate3d(0,100%,0);
            transition: .3s ease-in-out;
        }

        &:hover {

            &::before {
                transform: scaleX(1) translate3d(0,100%,0);
            }
        }
    }

    &__img {
        max-width: 197px;
        height: auto;
        margin-bottom: 20px;
    }

    &__name {
        color: $blue;
        font-size: 18px;
        letter-spacing: .024em;
        line-height: 24px;
        font-weight: 400;
    }

    &__stat {
        color: $blue;
        margin-bottom: 25px;
    }

    &__li {
        color: $blue;
        letter-spacing: .024em;
        line-height: 24px;
        font-weight: 400;

        > a {
            position: relative;

            &::before {
                content: "";
                position: absolute;
                bottom: -10px;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $blue;
            }

            &:hover {

                &::before {
                    animation-duration: 0.3s;
                    animation-timing-function: ease-in-out;
                    animation-fill-mode: both;
                    animation-name: linkWidth;
                }
            }
        }
    }
}

.related {

    &__title {
        text-align: right;
        font-size: 54px;
        font-weight: bold;
        letter-spacing: .02em;
        line-height: 62px;
        margin-bottom: 69px;
        padding-right: 2%;

        @media (max-width: $md - 1px) {
            font-size: 42px;
            margin-bottom: 20px;
        }
    }

    &_slides {
        padding-top: 100px;

        @media (max-width: $md - 1px) {
            padding-top: 0px;
            padding-bottom: 60px;
        }
    }

    &_slide {

        &__img {
            width: 100%;
            padding-top: 100%;
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;

            &_inner {
                bottom: 0px;
                position: absolute;
                width: 100%;
                height: 100%;
                @include bgCover();
                transform-origin: center center;
                transform: scale(1);
                transition: .3s cubic-bezier(0.215, 0.610, 0.355, 1);
            }

            &:hover {

                .related_slide__img_inner {
                    transform: scale(1.05);
                }
            }

            a {
                bottom: 0px;
                display: block;
                height: 100%;
                width: 100%;
                position: relative;
            }

            .date {
                position: absolute;
                left: 0;
                bottom: 0;
                background-color: #2522bf;
                color: #fff;
                font-size: 14px;
                line-height: 24px;
                padding: 9px 18px;
                letter-spacing: .02em;
                font-weight: 700;
                z-index: 1;
            }
        }

        &__title {
            font-size: 14px;
            font-weight: bold;
            line-height: 24px;
            margin-bottom: 11px;
        }

        &__exerp {
            font-size: 14px;
            line-height: 24px;
            margin-bottom: 10px;

            @media (max-width: $md - 1px) {
                max-height: 70px;
                overflow: hidden;
            }
        }

    }

    .slick {

        &-slide {
            margin: 0px 2%;

            @media (max-width: $md - 1px) {
                margin: 0px 5px;
            }
        }

        &-arrow {
            position: absolute;
            top: 0;
            width: 34px;
            height: 30px;
            cursor: pointer;
            font-size: 0;
            padding: 0;
            margin: 0;
            border: 0;
            background: transparent;
            @include bgContain;
            opacity: 0.3;
            transition: 0.15s ease-in-out;

            @media (max-width: $md - 1px) {
                top: auto;
                bottom: 0px;
            }
      
            &:hover {
              opacity: 1;
            }
      
            &.slick-disabled {
              opacity: 0.1;
              cursor: not-allowed;
            }
        }
    
        &-prev {
            right: calc(64px + 2%);
            background-image: url(../img/testi-left.svg);

            @media (max-width: $md - 1px) {
                right: auto;
                left: 0px;
            }
        }
    
        &-next {
            right: 2%;
            background-image: url(../img/testi-right.svg);

            @media (max-width: $md - 1px) {
                left: calc(64px + 2%);
                right: auto;
            }
        }
    }
}