.textphoto {
  &.spaced {
    margin: 100px 0 150px;
  }

  .wrap {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    @media (max-width: $md - 1px) {
      display: block;
    }
  }

  .mobShow {
    display: none;
    
    @media (max-width: $md - 1px) {
      display: block;
    }
  }

  .mobHide {
    display: block;

    @media (max-width: $md - 1px) {
      display: none;
    }
  }

  &_photo {
    width: calc(50% - 50px);
    margin-top: 165px;
    
    @media (max-width: $md - 1px) {
      width: calc(100% + 35px);
      margin: 30px 0 0;
    }

    &-wider {
      width: 51%;
      margin: 165px 6% 0;
    
      @media (max-width: $md - 1px) {
        width: calc(100% + 35px);
        margin: 30px 0 0;
      }
    }

    img {
      width: 100%;
    }
  }

  &_text {
    width: 50%;

    table {
      width: 100%;
      margin: 3em 0;
      table-layout: fixed;
      text-align: left;
      min-width: 700px;

      thead tr, tbody tr:not(:last-child) {
        border-bottom: 2px solid rgba($black, 0.3);
      }

      th, td {
        line-height: 24px;
        padding: 10px;

        &:not(:first-child) {
          border-left: 2px solid rgba($black, 0.3);
        }
      }

      td {
        font-size: 12px;
      }

      th {
        font-size: 14px;
        font-weight: 700;
        color: rgba($black, 0.8);
      }
    }

    &-textpage {
      @media (min-width: $md) {
        width: 80%;
      }
    }

    .textphoto_photo {
      margin: 2em 0;
    }

    @media (max-width: $md - 1px) {
      width: 100%;
    }

    &-slimer {
      width: 37%;
      
      @media (max-width: $md - 1px) {
        width: 100%;
      }
    }

    .buttons {
      display: flex;
      align-items: flex-start;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin: 15px -10px 0;

      .btn {
        margin: 10px;

        @media (max-width: $md - 1px) {
          width: calc(100% - 20px);
          max-width: 400px;
        }
      }
    }

    ul {
      font-size: 18px;
      line-height: 28px;
      margin: 1em 0;
    }

    li {
      padding-left: 20px;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        width: 4px;
        height: 4px;
        top: 12px;
        left: 0;
        background-color: $blue;
        border-radius: 50%;
      }
    }

    > * {
      margin: 1em 0;

      :first-child {
        margin-top: 0;
      }
  
      :last-child {
        margin-bottom: 0;
      }
    }

    .textphoto {
      &_listwrap {
        margin: 3em 0;

        ul li {
          font-size: 14px;
          line-height: 28px;
          position: relative;
          padding-left: 30px;

          &::before {
            content: '';
            background: url(../img/yes.svg);
            width: 16px;
            height: 28px;
            top: 0;
            left: 0;
            @include bgContain;
          }
        }
      }
    }
  }

  &--rev {

    .textphoto_photo {
      margin-top: 0px;
    }

    .textphoto_text {
      margin-top: 160px;
    }
  }

  &--no {

    .textphoto_photo {
      margin-top: 63px;
    }

    .textphoto_text {
      margin-top: 0px;
    }

    & + .linkblock {
      margin-top: 160px;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 28px;
    letter-spacing: .01em;

    @media (max-width: $md - 1px) {
      font-size: 14px;
      letter-spacing: 0em;
      line-height: 24px;
    }
  }

  &_intro {

    .text-lg {
      font-size: 54px;
      letter-spacing: .02em;
      line-height: 62px;
      margin: 93px 0px 17px;

      @media (max-width: $md - 1px) {
        font-size: 42px;
        letter-spacing: .0em;
        line-height: 50px;
      }

      &--noMgT {
        margin-top: 0px;
      }
    }
  }

  // .target {
  //   .textphoto {
  //     &_photo, &_text > * {
  //       opacity: 0;
  //       transform: translateY(20px);
  //     }
  //   }

  //   &.trigger {
  //     .textphoto {
  //       &_photo, &_text > * {
  //         opacity: 1;
  //         transform: translateY(0);
  //         transition: 0.7s $easing;
  //       }

  //       &_text > * {
  //         @for $k from 1 to 10 {
  //           &:nth-child(#{$k}) {
  //             $delay: 0.2s * $k;
  //             transition-delay: $delay;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}