.menu {
  @media (max-width: $md - 1px) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $blue;
    padding: 70px 35px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      bottom: 70px;
      height: 50px;
      width: 100%;
      background-image: $bfgrad;
      z-index: 10;
      pointer-events: none;
    }
  }

  &_inner {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: $md - 1px) {
      overflow: auto;
      display: block;
      width: calc(100% + 35px);
      height: 100%;
      padding-bottom: 50px;
    }
  }

  &_item {
    @media (max-width: $md - 1px) {
      &:not(:first-child) {
        margin-top: 10px;
      }
    }

    &.only-mob {
      display: none;

      @media (max-width: $md - 1px) {
        display: block;
      }
    }

    &.only-desk {
      display: none;

      @media (min-width: $md) {
        display: block;
      }
    }

    > .menu_link {
      padding: 8px 12px;
      line-height: 24px;
      font-size: 12px;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      font-weight: 700;
      position: relative;
      cursor: pointer;

      &--has-counter {
        padding-right: 25px;

        &::before {
          width: calc(100% - 37px) !important;
        }
      }

      @media (min-width: $md) and (max-width: $lg - 1px) {
        padding: 8px;
        letter-spacing: 0;

        &--has-counter {
          padding-right: 25px;

          &::before {
            width: calc(100% - 33px) !important;
          }
        }

        .ads_counter {
          margin-left: 8px;
        }
      }

      @media (max-width: $md - 1px) {
        padding: 13px 0 15px;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.01em;
        text-transform: none;
      }

      &::before {
        content: "";
        position: absolute;
        bottom: 8px;
        left: 12px;
        width: calc(100% - 24px);
        transform-origin: center center;
        transform: scaleX(0) translate3d(0, 100%, 0);
        height: 2px;
        background-color: currentColor;
        transition: transform 0.3s $easing;

        @media (min-width: $md) and (max-width: $lg - 1px) {
          width: calc(100% - 16px);
          left: 8px;
        }

        @media (max-width: $md - 1px) {
          bottom: 0;
          left: 0;
          width: 100%;
          background-color: rgba($black, 0.1);
          transform: none;
        }
      }

      &::after {
        content: "";
        position: absolute;
        width: 100%;
        height: 90px;
        top: calc(50% - 45px);
        left: 0;

        @media (max-width: $md - 1px) {
          display: none;
          width: 6px;
          height: 6px;
          border-left: 6px solid currentColor;
          border-top: 3px solid transparent;
          border-bottom: 3px solid transparent;
          top: -3px;
          left: auto;
          position: relative;
          margin-left: 15px;
        }
      }
    }

    @media (min-width: $md) {
      .menu_mega {
        background-color: $white;
        color: $black;
        position: absolute;
        border-top: 2px solid rgba($black, 0.08);
        min-width: 100%;
        transform: translate(-50%, -20px);
        left: -100vw;
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        transition: left 0.001ms linear 0.3s, opacity 0.3s $easing,
          transform 0.3s $easing;
      }

      &.active,
      &:hover {
        > .menu_link {
          &::before {
            transform: scaleX(1) translate3d(0, 100%, 0);
          }
        }
      }

      &.has-mega {
        &:hover,
        &.open {
          .menu_mega {
            left: 50%;
            transform: translate(-50%, 0px);
            pointer-events: auto;
            opacity: 1;
            transition: left 0.001ms linear, opacity 0.3s $easing 0.001ms,
              transform 0.3s $easing 0.001ms;
          }
        }
      }
    }

    @media (max-width: $md - 1px) {
      .menu_mega {
        background-color: $blue;
        position: fixed;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        transform: translateX(100%);
        pointer-events: none;
        opacity: 0;
        z-index: 2;
        transition: transform 0.001ms linear 0.4s, opacity 0.4s $easing;
      }

      &.has-mega {
        > .menu_link {
          &::after {
            display: inline-block;
          }
        }

        &.open {
          .menu_mega {
            left: 0%;
            top: 0%;
            transform: translateX(0%);
            pointer-events: auto;
            opacity: 1;
            transition: transform 0.001ms linear, opacity 0.4s $easing 0.001ms;
          }
        }
      }
    }
  }

  &_mega {
    top: 100%;
  }
}

@media (min-width: $md) {
  .header.scrolled {
    .menu_mega {
      &::before {
        height: 15px;
        top: -15px;
      }
    }
  }
}

.has-sub {
  position: relative;

  .submenu {
    top: 45px;
    position: absolute;
    opacity: 0;
    transform: translateY(20px);
    transition: 0.2s ease-in-out;
    visibility: hidden;
    padding: 34px;
    background-color: $white;
    cursor: default;

    @media (max-width: $md - 1px) {
      background-color: $blue;
      position: fixed;
      visibility: visible;
      padding-top: 164px;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      transform: translateY(0px);
      transform: translateX(100%);
      pointer-events: none;
      opacity: 0;
      z-index: 2;
      transition: transform 0.001ms linear 0.4s,
        opacity 0.4s cubic-bezier(0.2, 0.6, 0.3, 1);
    }

    li {
      font-size: 12px;
      text-transform: none;
      color: $black;
      font-weight: bold;
      cursor: pointer;

      @media (max-width: $md - 1px) {
        color: $white;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: 0.01em;
        font-weight: normal;
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }

      &:hover {
        color: $blue;

        a {
          &::after {
            transform: scaleX(1) translate3d(0, 100%, 0);
          }
        }
      }
    }

    a {
      white-space: nowrap;
      position: relative;

      &::after {
        content: "";
        position: absolute;
        bottom: -8px;
        left: 0px;
        height: 2px;
        width: 100%;
        transform-origin: center center;
        transform: scaleX(0) translate3d(0, 100%, 0);
        background-color: $blue;
        transition: transform 0.3s cubic-bezier(0.2, 0.6, 0.3, 1);
      }
    }

    .back_wrap {
      @media (min-width: $md) {
        display: none;
      }

      .back {
        color: $white;
        display: inline-block;
        font-size: 12px;
        line-height: 20px;
        letter-spacing: 0.1em;
        font-weight: 700;
        text-transform: uppercase;
        padding-left: 50px;
        position: fixed;
        top: 25px;
        left: 35px;
        cursor: pointer;

        svg {
          position: absolute;
          left: 0;
          top: 4px;
          width: 34px;
          height: 7px;
          transition: 0.3s cubic-bezier(0.2, 0.6, 0.3, 1);

          path {
            fill: $white;
          }
        }
      }
    }
  }

  > .menu_link:hover {
    @media (min-width: $md) {
      .submenu {
        opacity: 1;
        transform: translateY(0px);
        visibility: visible;
      }
    }
  }

  > .menu_link {
    > a {
      @media (max-width: $md - 1px) {
        display: inline-block;
      }
    }

    @media (max-width: $md - 1px) {
      &::after {
        content: "";
        display: inline-block;
        width: 6px;
        height: 6px;
        border-left: 6px solid;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        top: -3px;
        left: auto;
        position: relative;
        margin-left: 15px;
      }
    }
  }

  &.open {
    @media (max-width: $md - 1px) {
      .submenu {
        left: 0;
        top: 0;
        transform: translateX(0);
        pointer-events: auto;
        opacity: 1;
        transition: transform 0.001ms linear 0.4s,
          opacity 0.4s cubic-bezier(0.2, 0.6, 0.3, 1);
      }
    }
  }
}
