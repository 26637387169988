.hoverdots {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  &-12 {
    width: 264px;

    .hoverdots_dot {
      width: percentage(1/12);
      padding-bottom: percentage(1/12);
    }
  }

  &-10 {
    width: 220px;

    .hoverdots_dot {
      width: percentage(1/10);
      padding-bottom: percentage(1/10);
    }
  }

  &-8 {
    width: 176px;

    .hoverdots_dot {
      width: percentage(1/8);
      padding-bottom: percentage(1/8);
    }
  }

  &_dot {
    position: relative;
    
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      width: 2px;
      height: 2px;
      border-radius: 50%;
      transform: translate3d(-50%, -50%, 0) scale(1);
      background-color: $blue;
      transition: transform 1.5s $easing;
    }

    &.far, &.near, &.hover {
      &::before {
        transition: transform 0.3s $easing;
      }
    }

    &.far {
      &::before {
        transform: translate3d(-50%, -50%, 0) scale(1.5);
      }
    }

    &.near {
      &::before {
        transform: translate3d(-50%, -50%, 0) scale(2);
      }
    }

    &.hover {
      &::before {
        transform: translate3d(-50%, -50%, 0) scale(3);
      }
    }
  }
}