.form {
  position: relative;

  &__titleIcon {
    position: absolute;
    top: 0px;
    left: -92px;
    width: 60px;

    svg {
      width: 100%;
    }

    @media (max-width: $md - 1px) {
      left: 0px;
      top: -70px;
    }
  }

  &_hiddens {
    display: none;
  }

  &_submit {
    margin-top: 50px;

    @media (max-width: $md - 1px) {
      margin-top: 30px;
    }

    input {
      font-family: $font;

      @media (max-width: $md - 1px) {
        width: 100%;
        max-width: 400px;
      }  
    }
  }

  &_input {
    position: relative;

    &.error {
      .error_msg {
        color: $red;

        &::before {
          width: 100%;
          opacity: 1;
        }
      }
    }

    .error_msg {
      position: absolute;
      width: 100%;
      top: calc(100% - 2px);
      left: 0;
      font-size: 12px;
      line-height: 24px;
      padding-top: 2px;
      color: transparent;
      transition: color 0.2s $easing 0.1s;
      z-index: 2;

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 0;
        height: 2px;
        opacity: 0;
        background-color: $red;
        transition: width 0.2s $easing, opacity 0.2s $easing;
      }
    }

    &:not(:first-child) {
      margin-top: 40px;

      @media (max-width: $md - 1px) {
        margin-top: 30px;
      }
    }

    input, label {
      font-size: 14px;
      font-family: $font;
      font-weight: 400;
    }

    input {
      width: 100%;
      height: 42px;
      border: 0;
      margin: 0;
      padding: 0;
      border-radius: 0;
      background-color: transparent;
      border-bottom: 2px solid $dwhite;
      position: relative;
      z-index: 0;
    }

    label {
      position: absolute;
      left: 0;
      bottom: 0;
      line-height: 30px;
      padding-top: 4px;
      padding-bottom: 6px;
      z-index: 1;
      opacity: 0.5;
      transition: 0.3s $easing;
      cursor: text;
      user-select: none;
      pointer-events: none;

      a {
        display: inline;
      }
    }

    &.focus input, &.full input, input:focus {
      & + label {
        font-size: 12px;
        padding-bottom: 28px;
      }
    }

    &-file {
      input[data-file] {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        cursor: pointer;
        z-index: 1;
      }

      input[data-text] {
        pointer-events: none;
        z-index: 0;
        padding-right: 30px;
      }

      label {
        cursor: pointer;
        z-index: 2;
        pointer-events: auto;
      }

      &::before {
        content: '';
        width: 11px;
        height: 40px;
        position: absolute;
        right: 10px;
        top: 0;
        background-image: url(../img/file.svg);
        @include bgContain;
      }
    }

    &-checkbox {
      & + & {
        margin-top: 0;
      }

      &.error + & {
        padding-top: 20px;
      }

      &.error {
        .error_msg {
          padding-top: 0;

          &::before {
            display: none;
          }
        }

        label::before {
          border-color: $red;
        }
      }

      input[data-check] {
        display: none;

        &:not(:checked) + label::after {
          width: 0px;
          opacity: 0;
        }
      }

      label {
        display: inline-block;
        cursor: pointer;
        position: relative;
        padding: 0;
        padding-left: 28px;
        opacity: 1;
        pointer-events: auto;

        a {
          color: $blue;
          position: relative;

          &::before {
            content: '';
            position: absolute;
            left: 0;
            top: 100%;
            height: 2px;
            width: 100%;
            background-color: currentColor;
          }
        }

        &::before, &::after {
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          left: 0;
          top: 5px;
        }

        &::before {
          border: 2px solid rgba($black, 0.1);
          transition: border-color 0.2s $easing;
        }

        &::after {
          background-image: url(../img/yes.svg);
          background-repeat: no-repeat;
          background-size: 11px 11px;
          background-position: 4.5px center;
          opacity: 1;
          transition: 0.3s $easing;
        }
      }
    }
  }

  &_message {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 25px;
    background-color: $white;
    z-index: 5;

    svg, .icon {
      width: 100%;
      height: 120px;
      @include bgContain;
      margin-bottom: 30px;
    }

    .btn {
      margin-top: 40px;
    }

    &-success {
      .icon {
        background-image: url(../img/success.svg);
      }
    }

    &-error {
      .icon {
        background-image: url(../img/error.svg);
      }
    }
  }

  .form &_message {
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
  }
}

.filterGroup {
  margin-top: 30px;

  > .form_input {

    margin-bottom: 25px;
  }

}