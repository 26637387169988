.pagination {
    display: block;
    width: 100%;
    text-align: right;
    margin-bottom: 20px;

    @media (max-width: $md - 1px) {
        display: flex;
        justify-content: space-between;
      }

    .pagination__bullet {
        display: inline;
        color: $blue;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: .1em;
        margin: 0px 15px;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            width: 100%;
            height: 2px;
            background-color: $blue;
            bottom: -5px;
            transform-origin: center center;
            transform: scaleX(0) translate3d(0,100%,0);
            transition: .3s ease-in-out;
        }

        &--active {
            
            &::before {
                transform: scaleX(1) translate3d(0,100%,0);
            }

        }

        &:not(.pagination__bullet--empty):hover {

            &::before {
                transform: scaleX(1) translate3d(0,100%,0);
            }
        }
    }

    .arrow {
        display: inline-block;
        height: 7px;
        width: 35px;
        opacity: .3;
        transition: .1s ease-in-out;

        &:hover {
            opacity: 1;
        }
    }

    .prevArrow {
        background-image: url(../img/arrow_left-blue.svg);
        margin-right: 15px;
    }

    .nextArrow {
        background-image: url(../img/arrow_right-blue.svg);
        margin-left: 15px;
    }
}