.hero {
  position: relative;

  &_decors {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0;

    .container {
      .wrap {
        position: relative;

        &::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 68.4%;
          height: 200px;
          width: 100vw;
          background-color: $dwhite;

          @media (max-width: $md - 1px) {
            left: 50%;
            height: 80px;
          }
        }

        &.noCut {

          @media (max-width: $md - 1px) {
            &::before {
              content: none;
            }

            .hero_dots {
              left: calc(10% - 44px);
            }
          }
        }
      }
    }
  }

  &_wrap {
    display: flex !important;
    flex-direction: row;
    position: relative;
  }

  &_media {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    // filter: sepia(1) saturate(2000%) hue-rotate(180deg);

    .onlymob {
      display: none;
      
      @media (max-width: $md - 1px) {
        display: block;
      }
    }
    .nomob {
      display: block;
      
      @media (max-width: $md - 1px) {
        display: none;
      }
    }

    &-image, &-video {
      width: 100%;
      height: 100%;
      
      > * {
        width: 100%;
        height: 100%;
        @include bgCover;
        object-fit: cover;

        &.polyfill {
          min-width: 100%;
          min-height: 100%;
          width: auto;
          height: auto;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }

  &_bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;

    &::after {
      content: '';
      background-image: $bgradin;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      transform: translate3d(0, 0, 0);
    }
  }

  &_text {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    min-height: calc(100vh - 170px);
    padding: 120px 0 80px;
    color: $white;

    
    @media (max-width: $md - 1px) {
      padding: 70px 0 30px;
    }

    .wrap {
      max-width: 80%;
    
      @media (max-width: $md - 1px) {
        max-width: none;
      }
      
      h6 {
        margin-top: 60px;
    
        @media (max-width: $md - 1px) {
          margin-top: 20px;
        }
        &.techsummer-color {
          color: #ff66c4;
        }
      }

      h1, h2 {
        @media (max-width: $md - 1px) {
          font-size: 36px;
          line-height: 48px;
        }
        &.techsummer-color {
          color: #ff66c4;
        }
      }
    }

    &.story {
      .wrap h6 {
        margin-top: 0;
      }
    }
  }

  &_dots {
    position: absolute;
    z-index: 0;
    top: auto;
    bottom: -110px;
    right: calc(31.6% - 198px);

    @media (max-width: $md - 1px) {
      bottom: auto;
      right: auto;
      top: calc(100% - 66px);
      left: calc(50% - 44px);

      .hoverdots {
        width: 176px;
        height: 176px;
  
        &-12 {
          .hoverdots_dot {
            width: 12.5%;
            padding-bottom: 12.5%;
    
            &:nth-child(12n), &:nth-child(12n-1), &:nth-child(12n-2), &:nth-child(12n-3), &:nth-child(96) ~ .hoverdots_dot {
              display: none;
            }
          }
        }
      }
    }
  }
}